import { useState } from "react";
import ModalRegistrarCorreo from "../componentes/ModalRegistrarCorreo";
import { useUsuario } from "../context/UsuarioContext";
import { IoMdAlert } from "react-icons/io";

const VerificarCorreo =()=>{

    const { infoBasicData } = useUsuario();
    const [viewModal,setViewModal]=useState(false);

    return (
        <div className="mb-3">
        {
                !infoBasicData?.correo?
                <div className="card card-body">
                <div className="media">
                  <span className="tx-danger">
                  <IoMdAlert style={{fontSize:"70px"}}/>
                  </span>
                  <div className="media-body mg-l-20">
                    <h6 className="mg-b-10 tx-danger"><strong>No tiene registrado un correo electrónico</strong></h6>
                    <p className="tx-color-03 mg-b-0">No podrás recibir notificaciones del estado de sus trátemites registrados o recibidos. 
                    <button className="btn btn-sm btn-outline-primary" onClick={()=>setViewModal(true)}>registrar mi correo</button></p>
                  </div>
                </div>
              </div>
              :
              <div className="card card-body ht-lg-100">
              <div className="media">
                <span className="tx-warning">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download wd-60 ht-60">
                    <path d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11 13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V13ZM13 15.9888C13 15.4365 12.5523 14.9888 12 14.9888C11.4477 14.9888 11 15.4365 11 15.9888V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V15.9888Z" />
                  </svg>
                </span>
                <div className="media-body mg-l-20">
                  <h6 className="mg-b-10">La notificación del estado final de su trámite se enviará a su correo <strong>{infoBasicData?.correo}</strong></h6>
                  <p className="tx-color-03 mg-b-0">Debe estar atento a su correo para informate sobre el estado de su solicitud. </p>
                </div>
              </div>
            </div>
              }
              <ModalRegistrarCorreo
              show={viewModal}
              onHide={() => setViewModal(false)}
              />
        </div>

    );
}

export default VerificarCorreo;