import { Modal, Spinner } from "react-bootstrap";
import { useUsuario } from "../context/UsuarioContext";

function ModalLoad() {
  const { spinnerLoad } = useUsuario();

  return (
    <Modal
      size="sm"
      show={spinnerLoad}
      centered
      backdropClassName="zindex-1055"
      contentClassName="bg-transparent border-0"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col align-self-center text-center">
              <Spinner animation="grow" variant="light" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalLoad;
