import { NAME_LOCALSTORAGE_TOKEN } from "../config/configBase";
import { exitLogin, mostrarAlertaDanger, mostrarAlertaInfo } from "./alertas";

export function verificarCode(data) {
  


    switch (data.status) {
      case 403:
        localStorage.removeItem(NAME_LOCALSTORAGE_TOKEN); 
        mostrarAlertaDanger(data.mensaje);
        exitLogin(data.mensaje);
        break;
      case 200:
        mostrarAlertaInfo(data.mensaje);
        break;
      default:
        mostrarAlertaDanger(data.mensaje);
        console.log(`Sorry, we are out of ${data.status}.`);
    }

    return <></>
  }
  