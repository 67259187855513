import { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { httpGet, httpPost } from "../../http/request";
import { TfiReload } from "react-icons/tfi";
import { IoDocumentText } from "react-icons/io5";
import ModalVerDetalleTramite from "../../componentes/ModalVerDetalleTramite";
import { useUsuario } from "../../context/UsuarioContext";

const Procesados = () => {
  const { setSpinnerLoad } = useUsuario();
  const [viewModalTramiteDetalle, setViewModalTramiteDetalle] = useState(false);
  const [selectedTramite, setSelectedTramite] = useState({});

  const [tramitsIni, setTramitsIni] = useState([]);

  useEffect(() => {
    loadIniciados();
  }, []);

  const loadIniciados = () => {
    setSpinnerLoad(true);
    httpGet("/api/v1/actividad/listaActividadesProcesadas").then((d) => {
      console.log(d);
      if (d) {
        setTramitsIni(d);
      }
      setSpinnerLoad(false);
    });
  };

  const verDetallerTramite = (d) => {
    setViewModalTramiteDetalle(true);
    setSelectedTramite(d);
  };

  const trsTramits = (dTramits) =>
    dTramits.map((d, i) => {
      return d.procesadoMi ? (
        <tr key={i}>
          <td className="">{d.codeIniciado}</td>
          <td className="tx-color-03 tx-normal">{d.fechaInicio}</td>
          <td className="tx-medium text-center">{d.proceso}</td>
          <td
            className={`text-center tx-teal fw-bold ${
              d.codeEstado === "R" ? "text-danger" : ""
            }`}
          >
            {d.estado}
          </td>
          <td className="text-center">{d.solicitante}</td>
          <td className="text-end tx-pink">
            {d.posicion} ({d.actividad})
          </td>
          <td className="tx-medium text-end">
            <span
              className="text-center text-primary pointer"
              onClick={() => verDetallerTramite(d)}
            >
              Ver
            </span>
          </td>
        </tr>
      ) : (
        <></>
      );
    });

  return (
    <>
      <div className="content content-fixed">
        <Container>
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item active" aria-current="page">
                    Iniciados
                  </li>
                </ol>
              </nav>
              <h4 className="mg-b-0 tx-spacing--1">
                Lista y estado de Tramites Procesados
              </h4>
            </div>
            <div className="d-none d-md-block">
              <button
                className="btn btn-sm pd-x-15 btn-white"
                onClick={() => loadIniciados()}
              >
                <TfiReload />
              </button>

              {/*<button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-printer wd-10 mg-r-5"
                >
                  <polyline points="6 9 6 2 18 2 18 9"></polyline>
                  <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
                  <rect x="6" y="14" width="12" height="8"></rect>
                </svg>{" "}
                Print
              </button>
              <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file wd-10 mg-r-5"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>{" "}
                Generate Report
  </button>*/}
            </div>
          </div>

          <Row>
            <Col lg={12} xl={12} className="mg-t-10">
              <div className="card mg-b-10">
                {/*<div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                  <div>
                    <h6 className="mg-b-5">Your Most Recent Earnings</h6>
                    <p className="tx-13 tx-color-03 mg-b-0">
                      Your sales and referral earnings over the last 30 days
                    </p>
                  </div>
                </div>
                <div className="card-body pd-y-30">
                  <div className="d-sm-flex">
                    <div className="media">
                      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bar-chart-2"
                        >
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </div>
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">
                          Gross Earnings
                        </h6>
                        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">
                          $1,958,104
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bar-chart-2"
                        >
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </div>
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Tax Withheld
                        </h6>
                        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">
                          $234,769<small>.50</small>
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bar-chart-2"
                        >
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </div>
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Net Earnings
                        </h6>
                        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">
                          $1,608,469<small>.50</small>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
  */}
                <div className="table-responsive">
                  <table className="table table-dashboard mg-b-0">
                    <thead>
                      <tr>
                        <th className="fw-bold">Código</th>
                        <th className="fw-bold">Fecha Inicio</th>
                        <th className="text-center fw-bold">Tipo</th>
                        <th className="text-center fw-bold">Estado</th>
                        <th className="text-end fw-bold">Solicitante</th>
                        <th className="text-end fw-bold">Proceso</th>
                        <th className="text-end fw-bold">Ver Detalles</th>
                      </tr>
                    </thead>
                    <tbody>{trsTramits(tramitsIni)}</tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalVerDetalleTramite
        show={viewModalTramiteDetalle}
        onHide={() => setViewModalTramiteDetalle(false)}
        iniData={selectedTramite}
      />
    </>
  );
};

export default Procesados;
