import { Link, useNavigate } from "react-router-dom";
import { useUsuario } from "../../context/UsuarioContext";
import { BiSolidUserCircle } from "react-icons/bi";
import { BsDoorOpenFill } from "react-icons/bs";

const Topbar = () => {
  const { infoBasicData, setToken } = useUsuario();
  const navigate = useNavigate();

  function cl(e) {
    e.preventDefault();
    setToken("");
    navigate("/");
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light"
        style={{ position: "fixed", width: "100%", zIndex: 1000, top: 0 }}
      >
        <div className="container-fluid">
          <Link to={"/"} className="navbar-brand">
            Procesos Académicos
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to={"/"} className="nav-link">
                  Inicio
                </Link>
              </li>
              {(infoBasicData?.tipo ?? "publico") === "publico" ? (
                <></>
              ) : (
                <li className="nav-item">
                  <Link to={"/recibidos"} className="nav-link">
                    Recibidos
                  </Link>
                </li>
              )}
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Tramites
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to={"/iniciados"}>
                      Iniciados
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/recibidos"}>
                      Recibidos
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/procesados"}>
                      Procesados
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to={"/AprocesarFuturo"}>
                      En espera a recibir
                    </Link>
                  </li>

                  {/*<li>
                    <Link className="dropdown-item">
                      Another action
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link className="dropdown-item">
                      Something else here
                    </Link>
  </li>*/}
                </ul>
              </li>

              {/*<li className="nav-item">
                <Link
                  className="nav-link disabled"
                >
                  Disabled
                </Link>
  </li>*/}

              <li className="nav-item">
                <Link to={"/postulacion"} className="nav-link">
                  Postulaciones
                </Link>
              </li>
            </ul>
            <h3 className="me-1">
              <BiSolidUserCircle />
            </h3>
            <span className="me-3">
              {infoBasicData?.nombre_completo ?? "-"}
            </span>
            <h4 className="me-1 pointer" title="Cerrar Sesión" onClick={cl}>
              <BsDoorOpenFill />
            </h4>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Topbar;
