import { useCallback, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import {
  httpGet,
  httpGetBlob,
  httpPost,
  httpPostMultiPart,
} from "../../http/request";
import { LiaBirthdayCakeSolid, LiaMoneyBillWaveSolid } from "react-icons/lia";
import {
  FaTreeCity,
  FaSchoolFlag,
  FaSchoolCircleExclamation,
} from "react-icons/fa6";
import {
  FaRegIdCard,
  FaUserTag,
  FaHome,
  FaRegNewspaper,
  FaRegFilePdf,
  FaUniversity,
  FaCheckCircle,
  FaMapMarkedAlt,
} from "react-icons/fa";
import { MdOutlinePhoneIphone, MdSchedule, MdEmail } from "react-icons/md";
import { IoMaleFemaleSharp, IoSchool } from "react-icons/io5";
import userImg from "../../assets/img/user.png";
import { LuUser } from "react-icons/lu";
import { RiContactsLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import Select from "react-select";
import toast from "react-hot-toast";
import ModalExampleNumTransferencia from "../../seccions/modals/ModalExampleNumTransferencia";
import ModalExampleMontoTransferencia from "../../seccions/modals/ModalExampleMontoTransferencia";
import { useUsuario } from "../../context/UsuarioContext";

const Postulacion = () => {
  const { setSpinnerLoad } = useUsuario();
  const [viewModalAddAjunto, setViewModalAddAdjunto] = useState(false);
  const [viewModalNuevo, setViewModalNuevo] = useState(false);
  const [postulante, setPostulante] = useState({});
  const [registrarNuevo, setRegistrarNuevo] = useState(true);
  const [carrerasHabilitadas, setCarrerasHabilitadas] = useState([]);

  useEffect(() => {
    loadIniciados();
    loadCarrerasAdmision();
  }, []);

  const loadCarrerasAdmision = () => {
    httpGet("/api/v1/general/carrera_admision_habilitada").then((d) => {
      console.log(d);
      if (d) {
        setCarrerasHabilitadas(d);
      }
    });
  };

  const loadIniciados = () => {
    setViewModalNuevo(false);
    httpGet("/api/v1/postulante/buscarCi").then((d) => {
      console.log(d);
      if (d) {
        setRegistrarNuevo(false);
        setPostulante(d);
      } else {
        setRegistrarNuevo(true);
      }
    });
  };

  const descargarPdf = () => {
    setSpinnerLoad(true);
    httpGetBlob("/api/v1/postulante/pdfPostulacion", "postulacion.pdf").then(
      (d) => setSpinnerLoad(false)
    );
  };

  return (
    <>
      <div className="content content-fixed  content-profile">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="profile-sidebar pd-lg-r-25">
                <div className="row">
                  <div className="col-sm-auto">
                    <div className="avatar avatar-xxl avatar-online">
                      <img src={userImg} className="rounded-circle" alt="" />
                    </div>
                  </div>
                  {registrarNuevo ? (
                    <div className="col align-self-center">
                      <p className="m-0 mb-2">
                        <strong>
                          No tiene ninguna postulación válida y/o vigente.{" "}
                        </strong>
                        Para registrar una nueva postulación dale clik en el
                        siguiente botón
                      </p>
                      <button
                        className="btn btn-xs btn-primary flex-fill"
                        onClick={() => setViewModalNuevo(true)}
                      >
                        Realizar Nueva Postulación
                      </button>
                    </div>
                  ) : (
                    <div className="col align-self-center">
                      <h5 className="mg-b-2 tx-spacing--1 mt-3">
                        {postulante.nombres} {postulante.paterno}{" "}
                        {postulante.materno}
                      </h5>
                      <h6 className="">
                        <span className="tx-color-03 m-0">
                          Carrera/Programa:{" "}
                        </span>
                        {postulante.programa}
                      </h6>
                      <h6 className=" mg-b-0">{postulante.tipo_admision}</h6>
                      <p className=" mg-b-0 mt-2 tx-color-03">
                        Esta postulación vence el{" "}
                        <span className=" text-danger ">
                          {postulante.fec_vencimiento_format}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className="col-sm-12 mg-t-20 mg-sm-t-0 mg-lg-t-25">
                    {registrarNuevo ? (
                      <></>
                    ) : (
                      <div>
                        <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
                          Datos de generales
                        </label>
                        <Row>
                          <Col>
                            <ul className="list-unstyled profile-info-list">
                              <li>
                                <LiaBirthdayCakeSolid className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.fec_nacimiento_format}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Fecha de Nacimiento
                                </p>
                              </li>
                              <li>
                                <FaTreeCity className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.localidad}, {postulante.provincia}
                                  , {postulante.departamento}-{postulante.pais}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Lugar de nacimiento
                                </p>
                              </li>
                              <li>
                                <FaMapMarkedAlt className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.nacionalidad}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Nacionalidad
                                </p>
                              </li>
                            </ul>
                          </Col>

                          <Col>
                            <ul className="list-unstyled profile-info-list">
                              <li>
                                <FaRegIdCard className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.dip}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Número de documento
                                </p>
                              </li>
                              <li>
                                <IoMaleFemaleSharp className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.tipo_sexo}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Sexo
                                </p>
                              </li>
                              <li>
                                <FaUserTag className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.tipo_estado_civil}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Estado civil
                                </p>
                              </li>
                            </ul>
                          </Col>

                          <Col>
                            <ul className="list-unstyled profile-info-list">
                              <li>
                                <FaHome className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.direccion}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Dirección de domicilio
                                </p>
                              </li>
                              <li>
                                <MdOutlinePhoneIphone className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.celular}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Número de celular
                                </p>
                              </li>
                              <li>
                                <MdEmail className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.correo}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Correo electrónico
                                </p>
                              </li>
                            </ul>
                          </Col>
                        </Row>

                        <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">
                          Datos de académicos
                        </label>
                        <Row>
                          <Col>
                            <ul className="list-unstyled profile-info-list">
                              <li>
                                <FaSchoolFlag className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.colegio}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Colegio / Unidad Educativa
                                </p>
                              </li>
                              <li>
                                <FaSchoolCircleExclamation className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.tipoInstitucion}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Tipo Unidad Educativa
                                </p>
                              </li>
                            </ul>
                          </Col>

                          <Col>
                            <ul className="list-unstyled profile-info-list">
                              <li>
                                <IoSchool className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.anio_egreso}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Año de egreso
                                </p>
                              </li>
                              <li>
                                <MdSchedule className="h3 m-0" />{" "}
                                <span className="tx-color-03">
                                  {postulante.tipo_turno}
                                </span>
                                <p
                                  className="tx-color-03"
                                  style={{ fontSize: "9px" }}
                                >
                                  Turno de unidad educativa
                                </p>
                              </li>
                            </ul>
                          </Col>

                          <Col></Col>
                        </Row>

                        <div className="d-flex mg-b-25">
                          <button
                            className="btn btn-xs btn-warning flex-fill p-3"
                            onClick={() => setViewModalNuevo(true)}
                          >
                            <FaRegNewspaper className="me-1" />
                            Realizar Nueva Postulación
                          </button>
                          <button
                            className="btn btn-xs btn-white flex-fill p-2 mg-l-10"
                            onClick={() => descargarPdf()}
                          >
                            <FaRegFilePdf className="me-1 text-danger" />
                            Descargar Formulario para Pagar en Caja
                          </button>
                          <button
                            className="btn btn-xs btn-primary flex-fill mg-l-10"
                            onClick={() => setViewModalAddAdjunto(true)}
                          >
                            <LiaMoneyBillWaveSolid className="me-1" />
                            Pagar y Confirmar Postulación
                          </button>
                        </div>
                      </div>
                    )}
                    <p className="tx-13 tx-color-02 mg-b-25">
                      Las Postulaciones son validas durante 7 días después de
                      registrarlo, si los datos de postulación mostrados no son
                      correctos, puede realizar una nueva postulación ...{" "}
                      <Link>Leer más</Link>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <hr />
              <div className="h5">Carreras habilitadas para postulación</div>
              <div className="row row-xs mg-b-25">
                {carrerasHabilitadas.map((d, i) => (
                  <div
                    key={i}
                    className="col-sm-4 col-md-3 col-lg-3 col-xl-2  mg-t-10"
                  >
                    <div className="card card-profile">
                      {/*<img
                        src="../../assets/img/img26.jpg"
                        className="card-img-top"
                        alt=""
                />*/}
                      <div className="card-body tx-13">
                        <div className="text-center m-0">
                          <h5 className="text-primary">{d.carrera}</h5>
                          <p>{d.facultad}</p>
                          <div>
                            {d?.admisiones.map((dd, ii) => (
                              <div key={ii}>
                                <FaCheckCircle className="text-success" />{" "}
                                {dd.tipo_admision}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalAddAdjuntoPostulacion
        show={viewModalAddAjunto}
        onHide={() => setViewModalAddAdjunto(false)}
        postulante={postulante}
      />
      <ModalNuevaPostulacion
        show={viewModalNuevo}
        onHide={() => setViewModalNuevo(false)}
        postulante={postulante}
        onSuccess={() => loadIniciados()}
      />
    </>
  );
};

export default Postulacion;

const ModalAddAdjuntoPostulacion = (props) => {
  const [viewModalExampleNumTrans, setViewModalExampleNumTrans] =
    useState(false);
  const [viewModalExampleMontoTrans, setViewModalExampleMontoTrans] =
    useState(false);

  const [fileValue, setfileValue] = useState({});
  const [codeTransfer, setCodeTransfer] = useState({});
  const [monto, setMonto] = useState({});

  const handleFileChange = (event) => {
    const { files } = event.target;
    setfileValue(files[0]);
  };

  const cargarAdjunto = () => {
    let formData = new FormData();

    formData.append("fi", fileValue);
    formData.append("code", codeTransfer);
    formData.append("monto", monto);

    console.log(formData);

    httpPostMultiPart(formData, "/api/v1/postulante/adjuntarPago").then((d) => {
      console.log(d);
      if (d) {
        //setPostulante(d);
      }
    });
  };

  return (
    <Modal
      show={props.show}
      size={"xl"}
      onHide={props.onHide}
      centered={true}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Body className="tx-13">
        <span
          className="close pos-absolute t-20 r-20 text-secondary pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={props.onHide}>
            {" "}
            ×
          </span>
        </span>
        <h5 className="tx-18 tx-sm-20 mg-b-20">Pago y Adjunto de Recibo</h5>
        <div>
          <div>
            <p className="text-danger">
              ** Realiza cuidadosamente estos pasos y verifica que la
              información registrada es correcta
            </p>

            <div className="divider-text mb-3 text-center">
              Paso 1 - Realizar el Pago o Transferencia
            </div>

            <ul>
              <li>
                <p className="">
                  <strong>
                    Debe depositar o realizar transferencia de{" "}
                    {props.postulante.id_nacionalidad === 1 ? "300" : "1500"} bs{" "}
                  </strong>{" "}
                  a la cuenta:{" "}
                  <strong>
                    10000040734093 UAP FONDOS MATRICULA ESTUDIANTES
                  </strong>
                </p>
              </li>
              <li>
                <p className="">
                  En la descripcrición <strong>Glosa</strong> de la
                  transferencia debe ingresar la siguiente información:{" "}
                  <strong>[MATRICULACION - Nombre del estudiante]</strong>
                </p>
              </li>
            </ul>
          </div>

          <div>
            <div className="divider-text pb-1 text-center pt-3">
              Paso 2 - Adjuntar comprobante de pago/transferencia
            </div>

            <div>
              <Row>
                <Col sm={12} md={6}>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">
                      Nro Transferencia/Deposito
                    </label>
                    <input
                      name="transferencia"
                      className="form-control"
                      type="text"
                      placeholder="000000000"
                      onChange={(e) => setCodeTransfer(e.target.value)}
                    />
                    <p className="help-block">
                      <Link onClick={() => setViewModalExampleNumTrans(true)}>
                        Ver ejemplo
                      </Link>
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">Monto Depositado</label>
                    <input
                      name="monto"
                      className="form-control"
                      type="number"
                      placeholder={
                        props.postulante.id_nacionalidad === 1 ? "300" : "1500"
                      }
                      onChange={(e) => setMonto(e.target.value)}
                    />
                    <p className="help-block">
                      <Link onClick={() => setViewModalExampleMontoTrans(true)}>
                        Ver ejemplo
                      </Link>
                    </p>
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="form-group">
                    <label htmlFor="exampleInputFile">Adjuntar Recibo</label>
                    <input
                      name="file"
                      className="form-control"
                      type="file"
                      id="exampleInputFile"
                      accept=".jpg,.png"
                      required={true}
                      onChange={handleFileChange}
                    />
                    <p className="help-block tx-color-03">
                      Foto del Recibo o captura de pantalla de la transferencia
                      realizada
                    </p>
                  </div>
                </Col>

                <Col sm={12} className="text-center">
                  <button
                    className="btn btn-secondary "
                    onClick={() => props.onHide()}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-primary ms-3"
                    onClick={() => cargarAdjunto()}
                  >
                    Registrar Datos de Pago/Transferencia
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <br />
      </Modal.Body>

      <ModalExampleNumTransferencia
        show={viewModalExampleNumTrans}
        onHide={() => setViewModalExampleNumTrans(false)}
      />

      <ModalExampleMontoTransferencia
        show={viewModalExampleMontoTrans}
        onHide={() => setViewModalExampleMontoTrans(false)}
      />
    </Modal>
  );
};

const ModalNuevaPostulacion = (props) => {
  const [positionStep, setPositionStep] = useState(1);
  const [dataPersona, setDataPersona] = useState({});
  const [datosNuevaPostulacion, setDatosNuevaPostulacion] = useState({});

  useEffect(() => {
    const loadDataPersonal = () => {
      httpGet("/api/v1/postulante/buscarDatosPersonaCi").then((d) => {
        console.log(d);
        if (d) {
          setDataPersona(d);
        }
      });
    };

    loadDataPersonal();
  }, []);

  useEffect(() => {
    if (props.show) {
      setDatosNuevaPostulacion({});
      setPositionStep(1);
    }
  }, [props.show]);

  const next = () => {
    setPositionStep(positionStep + 1);
  };

  const prev = () => {
    setPositionStep(positionStep - 1);
  };

  const crearNuevaPostulacion = (d) => {
    let data = Object.assign({}, datosNuevaPostulacion, d);
    httpPost(data, "/api/v1/postulante/nuevaPostulacion").then((d) => {
      if (d) {
        props.onSuccess();
      }
    });
  };

  return (
    <Modal
      show={props.show}
      size={"lg"}
      onHide={props.onHide}
      centered={true}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Body className="tx-13 p-0">
        <span
          className="close pos-absolute t-20 r-20 text-secondary pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></span>
        <div className="mb-2">
          <ul className="steps steps-tab steps-sm steps-justified">
            <li
              className={`step-item ${
                positionStep === 1
                  ? "active"
                  : positionStep > 1
                  ? "complete"
                  : "disabled"
              }`}
            >
              <Link className={"step-link"}>
                <span className="step-icon">
                  <LuUser />
                </span>
                <span className="step-title">Información Personal</span>
              </Link>
            </li>
            <li
              className={`step-item ${
                positionStep === 2
                  ? "active"
                  : positionStep > 2
                  ? "complete"
                  : "disabled"
              }`}
            >
              <Link className={"step-link"}>
                <span className="step-icon">
                  <RiContactsLine />
                </span>
                <span className="step-title">Datos Generales</span>
              </Link>
            </li>
            <li
              className={`step-item ${
                positionStep === 3
                  ? "active"
                  : positionStep > 3
                  ? "complete"
                  : "disabled"
              }`}
            >
              <Link className={"step-link"}>
                <span className="step-icon">
                  <FaUniversity />
                </span>
                <span className="step-title">Datos de Postulación</span>
              </Link>
            </li>
          </ul>
        </div>

        <div className="p-3">
          <InformacionPersonalForm
            oculto={positionStep !== 1}
            cancelar={() => props.onHide()}
            prev={() => prev()}
            next={() => next()}
            persona={dataPersona}
            onSuccess={(d) => {
              setDatosNuevaPostulacion(
                Object.assign({}, datosNuevaPostulacion, d)
              );
            }}
          />
          <DatosGeneralesForm
            oculto={positionStep !== 2}
            cancelar={() => props.onHide()}
            prev={() => prev()}
            next={() => next()}
            persona={dataPersona}
            postulante={props.postulante}
            onSuccess={(d) => {
              setDatosNuevaPostulacion(
                Object.assign({}, datosNuevaPostulacion, d)
              );
            }}
          />
          <DatosPostulacionForm
            oculto={positionStep !== 3}
            cancelar={() => props.onHide()}
            prev={() => prev()}
            next={() => next()}
            onSuccess={(d) => crearNuevaPostulacion(d)}
          />
        </div>
        <br />
      </Modal.Body>
    </Modal>
  );
};

const InformacionPersonalForm = (props) => {
  const [expedidos, setExpedidos] = useState([]);
  const [sexos, setSexos] = useState([]);
  const [tipoEstadosCivil, setTipoEstadosCivil] = useState([]);

  const [formNombres, setFormNombres] = useState("");
  const [formPaterno, setFormPaterno] = useState("");
  const [formMaterno, setFormMaterno] = useState("");
  const [formCi, setFormCi] = useState("");
  const [formIdExpedido, setFormIdExpedido] = useState(0);
  const [formFecNacimiento, setFormFecNacimiento] = useState("");
  const [formIdSexo, setFormIdSexo] = useState(0);
  const [formIdCivil, setFormIdCivil] = useState(0);
  const [formCelular, setFormCelular] = useState("");
  const [formCorreo, setFormCorreo] = useState("");
  const [formDireccion, setFormDireccion] = useState("");

  useEffect(() => {
    loadExpedidos();
    loadSexos();
    loadEstadosCiviles();
  }, []);

  useEffect(() => {
    const loadDataPersonal = () => {
      let d = props.persona;
      if (d) {
        setFormNombres(d.nombres);
        setFormPaterno(d.paterno);
        setFormMaterno(d.materno);
        setFormCi(d.ci);
        setFormIdExpedido(d.code_expedido);
        setFormFecNacimiento(d.fecha_nacimiento);
        setFormIdSexo(d.code_sexo);
        setFormIdCivil(d.code_civil);
        setFormCelular(d.celular);
        setFormCorreo(d.email);
        setFormDireccion(d.direccion);
      }
    };

    loadDataPersonal();
  }, [props.persona]);

  const loadExpedidos = () => {
    httpGet("/api/v1/general/expedidos").then((d) => {
      console.log(d);
      if (d) {
        setExpedidos(d);
      }
    });
  };
  const loadSexos = () => {
    httpGet("/api/v1/general/sexos").then((d) => {
      console.log(d);
      if (d) {
        setSexos(d);
      }
    });
  };

  const continuarProceso = () => {
    if (
      !(
        formNombres &&
        formPaterno &&
        formMaterno &&
        formCi &&
        formIdExpedido &&
        formFecNacimiento &&
        formIdSexo &&
        formIdCivil &&
        formCelular &&
        formCorreo &&
        formDireccion
      )
    ) {
      toast.error("Debe llenar todos los datos del formulario");
      return;
    }
    let data = {
      nombres: formNombres,
      paterno: formPaterno,
      materno: formMaterno,
      ci: formCi,
      expedido: formIdExpedido,
      fec_nacimiento: formFecNacimiento,
      sexo: formIdSexo,
      civil: formIdCivil,
      celular: formCelular,
      correo: formCorreo,
      direccion: formDireccion,
    };
    props.onSuccess(data);
    props.next();
  };

  const loadEstadosCiviles = () => {
    httpGet("/api/v1/general/estados_civiles").then((d) => {
      console.log(d);
      if (d) {
        setTipoEstadosCivil(d);
      }
    });
  };

  return (
    <>
      <div className={`step-2 ${props.oculto ? "d-none" : ""}`}>
        <div className="h5 tx-color-03 mb-3 text-center">
          Revisa y Confirma la información encontrada
        </div>
        <Row>
          <Col sm={12} lg={6} md={6} className="form-group">
            <label className="form-label tx-color-03">Nombres</label>
            <input
              type="text"
              className="form-control"
              placeholder="Nombres"
              value={formNombres}
              onChange={(e) => setFormNombres(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">Primer Apellido</label>
            <input
              type="text"
              className="form-control"
              placeholder="Primer Apellido"
              value={formPaterno}
              onChange={(e) => setFormPaterno(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">Segundo Apellido</label>
            <input
              type="text"
              className="form-control"
              placeholder="Segundo Apellido"
              value={formMaterno}
              onChange={(e) => setFormMaterno(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">
              N° de Cédula de Identidad
            </label>
            <input
              type="text"
              className="form-control"
              disabled={true}
              value={formCi}
            />
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">Expedido en</label>
            <select
              className="form-select"
              required={true}
              value={formIdExpedido}
              onChange={(e) => setFormIdExpedido(e.target.value)}
            >
              <option value={0}>Selecione una Opción</option>
              {expedidos.map((d, i) => (
                <option key={i} value={d.code}>
                  {d.descripcion}
                </option>
              ))}
            </select>
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">
              Fecha de Nacimiento
            </label>
            <input
              type="date"
              className="form-control"
              placeholder="Fecha de nacimiento"
              value={formFecNacimiento}
              onChange={(e) => setFormFecNacimiento(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">Género</label>
            <select
              className="form-select"
              required={true}
              value={formIdSexo}
              onChange={(e) => setFormIdSexo(e.target.value)}
            >
              <option value={0}>Selecione una Opción</option>
              {sexos.map((d, i) => (
                <option key={i} value={d.code}>
                  {d.tipo_sexo}
                </option>
              ))}
            </select>
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">Estado Civil</label>
            <select
              className="form-select"
              required={true}
              value={formIdCivil}
              onChange={(e) => setFormIdCivil(e.target.value)}
            >
              <option value={0}>Selecione una Opción</option>
              {tipoEstadosCivil.map((d, i) => (
                <option key={i} value={d.code}>
                  {d.tipo_estado_civil}
                </option>
              ))}
            </select>
          </Col>
          <Col sm={12} lg={3} md={6} className="form-group">
            <label className="form-label tx-color-03">Número de Celular</label>
            <input
              type="text"
              className="form-control"
              placeholder="Número de teléfono celular"
              value={formCelular}
              onChange={(e) => setFormCelular(e.target.value)}
            />
          </Col>
          <Col sm={12} lg={6} md={6} className="form-group">
            <label className="form-label tx-color-03">Correo electrónico</label>
            <input
              type="text"
              className="form-control"
              disabled={props.persona.email ?? false}
              value={formCorreo}
            />
          </Col>
          <Col sm={12} lg={12} md={12} className="form-group">
            <label className="form-label tx-color-03">Dirección</label>
            <input
              type="text"
              className="form-control"
              placeholder="Dirección de Domicilio"
              value={formDireccion}
              onChange={(e) => setFormDireccion(e.target.value)}
            />
          </Col>
        </Row>
        <div className="d-flex mt-3">
          <button
            className="btn btn-outline-light me-auto"
            onClick={() => props.cancelar()}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary ms-3"
            onClick={() => continuarProceso()}
          >
            Continuar
          </button>
        </div>
      </div>
    </>
  );
};

const DatosGeneralesForm = (props) => {
  const [provincias, setProvincias] = useState([]);

  const [formIdLocalidad, setFormIdLocalidad] = useState(0);
  const [formIdNacionalidad, setFormIdNacionalidad] = useState(0);
  const [formUnidadEducativa, setFormUnidadEducativa] = useState("");
  const [formAnioEgreso, setFormAnioEgreso] = useState("");
  const [formIdTipoUE, setFormIdTipoUE] = useState(0);
  const [formIdTipoTurno, setFormIdTipoTurno] = useState(0);

  useEffect(() => {
    loadProvincias();
  }, []);

  const { postulante, persona } = props;
  const fetchPostulante = useCallback(() => {
    let d = persona;
    if (d) {
      setFormIdLocalidad(d.code_localidad);
    }
    let dd = postulante;
    if (dd) {
      setFormIdNacionalidad(dd.id_nacionalidad);
      setFormUnidadEducativa(dd.colegio);
      setFormAnioEgreso(dd.anio_egreso);
      setFormIdTipoUE(dd.id_tipo_institucion);
      setFormIdTipoTurno(dd.id_tipo_turno);
    }
  }, [postulante, persona]);

  useEffect(() => {
    /*const loadDataPersonal = () => {
      let d = props.persona;
      if (d) {
        setFormIdLocalidad(d.code_localidad);
      }
      let dd = props.postulante;
      if (dd) {
        setFormIdNacionalidad(dd.id_nacionalidad);
        setFormUnidadEducativa(dd.colegio);
        setFormAnioEgreso(dd.anio_egreso);
        setFormIdTipoUE(dd.id_tipo_institucion);
        setFormIdTipoTurno(dd.id_tipo_turno);
      }
    };

    loadDataPersonal();*/
    fetchPostulante();
  }, [fetchPostulante]);

  useEffect(() => {
    const loadDataPostulante = () => {
      let d = props.postulante;
      if (d) {
        setFormIdNacionalidad(d.id_nacionalidad);
        setFormUnidadEducativa(d.colegio);
        setFormAnioEgreso(d.anio_egreso);
        setFormIdTipoUE(d.id_tipo_institucion);
        setFormIdTipoTurno(d.id_tipo_turno);
      }
    };
    loadDataPostulante();
  }, [props.postulante]);

  const continuarProceso = () => {
    if (
      !(
        formIdLocalidad &&
        formIdNacionalidad &&
        formUnidadEducativa &&
        formAnioEgreso &&
        formIdTipoUE &&
        formIdTipoTurno
      )
    ) {
      toast.error("Debe llenar todos los datos del formulario");
      return;
    }
    let data = {
      localidad: formIdLocalidad,
      nacionalidad: formIdNacionalidad,
      unidad_educativa: formUnidadEducativa,
      anio_egreso: formAnioEgreso,
      tipo_ue: formIdTipoUE,
      tipo_turno: formIdTipoTurno,
    };
    props.onSuccess(data);
    props.next();
  };

  const loadProvincias = () => {
    httpGet("/api/v1/general/provincias_completo").then((d) => {
      console.log(d);
      if (d) {
        let l = [];
        d.forEach((e) => {
          l.push({ value: e["code"], label: e["ubicacion"] });
        });
        setProvincias(l);
      }
    });
  };

  return (
    <div className={`step-2 ${props.oculto ? "d-none" : ""}`}>
      <div className="h5 tx-color-03 mb-3 text-center">
        Revisa y Confirma la información encontrada
      </div>
      <Row>
        <Col sm={12} className="form-group">
          <label className="form-label tx-color-03">Lugar de Nacimiento</label>
          <Select
            options={provincias}
            autoFocus={true}
            placeholder={"Selecione el lugar de nacimiento"}
            value={provincias.filter(
              (option) => option.value === formIdLocalidad
            )}
            onChange={(e) => setFormIdLocalidad(e.value)}
          />
        </Col>
        <Col sm={12} lg={6} md={6} className="form-group">
          <label className="form-label tx-color-03">Nacionalidad</label>
          <select
            className="form-select"
            required={true}
            value={formIdNacionalidad}
            onChange={(e) => setFormIdNacionalidad(e.target.value)}
          >
            <option value={0}>Selecione una Opción</option>
            <option value="1"> Boliviana</option>
            <option value="2"> Extranjera</option>
          </select>
        </Col>
        <Col sm={12} lg={6} md={6} className="form-group">
          <label className="form-label tx-color-03">Unidad Educativa</label>
          <input
            type="text"
            className="form-control"
            placeholder="Unidad Educativa"
            value={formUnidadEducativa}
            onChange={(e) => setFormUnidadEducativa(e.target.value)}
          />
        </Col>
        <Col sm={12} lg={6} md={6} className="form-group">
          <label className="form-label tx-color-03">Año de Egreso</label>
          <input
            type="text"
            className="form-control"
            placeholder="Año de Egreso Bachiller"
            value={formAnioEgreso}
            onChange={(e) => setFormAnioEgreso(e.target.value)}
          />
        </Col>
        <Col sm={12} lg={6} md={6} className="form-group">
          <label className="form-label tx-color-03">Tipo U.E.</label>
          <select
            className="form-select"
            required={true}
            value={formIdTipoUE}
            onChange={(e) => setFormIdTipoUE(e.target.value)}
          >
            <option value={0}>Selecione una Opción</option>
            <option value="1"> Fiscal</option>
            <option value="2"> Particular</option>
          </select>
        </Col>
        <Col sm={12} lg={6} md={6} className="form-group">
          <label className="form-label tx-color-03">Turno</label>
          <select
            className="form-select"
            required={true}
            value={formIdTipoTurno}
            onChange={(e) => setFormIdTipoTurno(e.target.value)}
          >
            <option value={0}>Selecione una Opción</option>
            <option value="1"> Mañana</option>
            <option value="2"> Tarde</option>
            <option value="3"> Noche</option>
          </select>
        </Col>
        <Col sm={12}>
          <hr />
        </Col>{" "}
        {/*<Col sm={12} lg={4} md={4} className="form-group">
          <label className="form-label tx-color-03">Turno</label>
          <select
            className="form-select"
            required={true}
            value={formIdTipoTurno}
            onChange={(e) => setFormIdTipoTurno(e.target.value)}
          >
            <option value={0}>Selecione una Opción</option>
            <option value="1"> Mañana</option>
            <option value="2"> Tarde</option>
            <option value="3"> Noche</option>
          </select>
        </Col>*/}
      </Row>
      <div className="d-flex mt-3">
        <button
          className="btn btn-outline-light me-auto"
          onClick={() => props.cancelar()}
        >
          Cancelar
        </button>
        <button className="btn btn-outline-light" onClick={() => props.prev()}>
          Anterior
        </button>
        <button
          className="btn btn-primary ms-3"
          onClick={() => continuarProceso()}
        >
          Continuar
        </button>
      </div>
    </div>
  );
};

const DatosPostulacionForm = (props) => {
  const [carreras, setCarreras] = useState([]);
  const [tiposAdmision, setTiposAdmision] = useState([]);

  const [formIdCarrera, setFormIdCarrera] = useState(0);
  const [formIdAdmision, setFormIdAdmision] = useState(0);

  useEffect(() => {
    loadFacultades();
  }, []);

  const loadFacultades = () => {
    httpGet("/api/v1/general/carrera_completo").then((d) => {
      if (d) {
        let l = [];
        d.forEach((e) => {
          l.push({
            value: e["code"],
            label: `${e["carrera"]} - ${e["facultad"]}`,
          });
        });
        setCarreras(l);
      }
    });
  };

  const loadTiposAdmision = (id) => {
    httpPost({ id }, "/api/v1/general/tipos_admision").then((d) => {
      if (d) {
        setTiposAdmision(d);
      }
    });
  };

  const selecCarrera = (d) => {
    setFormIdCarrera(d);
    loadTiposAdmision(d);
    setFormIdAdmision(0);
  };

  const continuarProceso = () => {
    if (!(formIdCarrera && formIdAdmision)) {
      toast.error("Debe selecionar la información requerida en el formulario");
      return;
    }
    let data = {
      carrera: formIdCarrera,
      admision: formIdAdmision,
    };
    props.onSuccess(data);
    //props.guardar();
  };

  return (
    <div className={`step-2 ${props.oculto ? "d-none" : ""}`}>
      <Row>
        <Col sm={12} className="form-group">
          <label className="form-label tx-color-03">Carrera</label>
          <Select
            options={carreras}
            autoFocus={true}
            placeholder={"Seleccione una carrera"}
            onChange={(e) => selecCarrera(e.value)}
          />
          {formIdCarrera && tiposAdmision.length === 0 ? (
            <p className="text-danger m-0">
              La Carrera Selecionada no tiene habilitada ningún tipo de admisión
            </p>
          ) : (
            <></>
          )}
        </Col>
        <Col sm={12} lg={6} md={6} className="form-group">
          <label className="form-label tx-color-03">Tipo Admisión</label>
          <select
            className="form-select"
            required={true}
            onChange={(e) => setFormIdAdmision(e.target.value)}
          >
            <option value={0}>Selecione una Opción</option>
            {tiposAdmision.map((d, i) => (
              <option key={i} value={d.code}>
                {d.tipo_admision}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <div className="d-flex mt-3">
        <button
          className="btn btn-outline-light me-auto"
          onClick={() => props.cancelar()}
        >
          Cancelar
        </button>
        <button className="btn btn-outline-light" onClick={() => props.prev()}>
          Anterior
        </button>
        {formIdCarrera && formIdAdmision ? (
          <button
            className="btn btn-success ms-3"
            onClick={() => continuarProceso()}
          >
            Registrar Mi Postulación
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
