import { useState } from "react";
import { mostrarAlertaInfo } from "../functions/alertas";
import { httpPost } from "../http/request";
import { Button, Modal, Row } from "react-bootstrap";
import { useUsuario } from "../context/UsuarioContext";
import { useNavigate } from "react-router-dom";

const ModalRegistrarCorreo = (props) => {
  const { infoBasicData, loadData, setSpinnerLoad } = useUsuario();

  const [email, setEmail] = useState(infoBasicData?.correo);

  const handleSubmit = (event) => {
    console.log(email);
    setSpinnerLoad(true);
    httpPost({ email }, "/api/v1/usuario/registrarCorreo").then((d) => {
      console.log(d);
      if (d) {
        props.onHide();
        loadData();
      }
      setSpinnerLoad(false);
    });
  };

  return (
    <>
      <Modal
        show={props.show}
        size={"md"}
        onHide={props.onHide}
        centered={true}
        keyboard={false}
      >
        <Modal.Body>
          <h5 className="tx-18 tx-sm-20 mg-b-20">
            Registrar/Modificar Correo Electrónico{" "}
          </h5>

          <div className="form-group col-sm-12">
            <label className="mb-3"></label>
            <div className="input-group mb-3">
              <input
                type={"text"}
                className="form-control"
                placeholder={"Ingrese su correo electrónico"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="col-12 text-center">
            <Button variant="primary" onClick={handleSubmit}>
              Registrar
            </Button>
          </div>
          {/*<hr />
          <p className="tx-13 tx-color-03 mg-b-30">
            You can add more information than what you see here, such as address
            and birthday by clicking{" "}
            <span className="tx-color-02">Add More Fields</span> button below to
            bring up more options.
    </p>*/}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRegistrarCorreo;
