import './App.css';
import Topbar from './seccions/topbar/Topbar';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import DetalleTramite from './pages/detalleTramite/DetalleTramite';
import Iniciados from './pages/iniciados/Iniciados';
import { UsuarioProvider, useUsuario } from './context/UsuarioContext';
import { Toaster } from 'react-hot-toast';
import Recibidos from './pages/recibidos/Recibidos';
//import { URL_API } from './config/configBase';
import Postulacion from './pages/postulacion/Postulacion';
import ModalLoad from './componentes/ModaLoad';
import FueraDeLinea from './pages/fueraDeLinea/FueraDeLinea';
import Procesados from './pages/procesados/Procesados';
import AprocesarFuturo from './pages/procesados/AprocesarFuturo';


// eslint-disable-next-line import/no-anonymous-default-export
export default () => <UsuarioProvider>
  <App></App>
  <div><Toaster /></div>
</UsuarioProvider>

function App() {

  /*
  const [isOnline, setIsOnline] = useState(false);

  useEffect(() => {
    const checkConnection = async () => {
      try {
        // Realizar una solicitud a un enlace específico (puedes cambiarlo según tus necesidades)
        const response = await fetch(URL_API);

        // Verificar si la solicitud fue exitosa (código de respuesta 2xx)
        if (response.ok) {
          setIsOnline(true);
        } else {
          setIsOnline(false);
        }
      } catch (error) {
        // Error al realizar la solicitud, asumimos que no hay conexión
        setIsOnline(false);
      }
    };

    // Verificar la conexión inicialmente
    checkConnection();

    // Configurar un temporizador para verificar la conexión periódicamente
    const intervalId = setInterval(checkConnection, 5000); // Verificar cada 5 segundos

    // Limpiar el temporizador al desmontar el componente
    return () => clearInterval(intervalId);
  }, []);

*/



  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    iniciar();

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);


  const { token } = useUsuario();


  
  const iniciar=()=>{
/*
0 requestForToken().then((tk)=>{
        if(tk!==null){
          setTkApp(tk);
        }
      });

    onMessageListener()
      .then((payload) => {
        console.log("llegó mensaje");
        console.log(payload);
        toastNotificacion(payload?.notification?.title, payload?.notification?.body);
        //setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
      })
      .catch((err) => console.log('failed: ', err));
*/

  }



  if (!isOnline) {
    return (
      <FueraDeLinea/>
    );
  }

  if (!token) {

    return (
      <div className='m'>
        <Routes basename={'/'}>
          <Route exact path="/*" element={<Login />} />
        </Routes>
      <ModalLoad 
      style={{ zIndex: "1055" }}/>
      </div>
    )

  }


  return (
    <div className="App">

      <Topbar></Topbar>

      <div className='' style={{ marginTop: "50px" }}>
        <Routes>
          <Route exact path="/*" element={<Home />} />
          <Route exact path="/tramite/detalle" element={<DetalleTramite />} />
          <Route exact path="/iniciados" element={<Iniciados />} />
          <Route exact path="/recibidos" element={<Recibidos />} />
          <Route exact path="/procesados" element={<Procesados />} />
          <Route exact path="/AprocesarFuturo" element={<AprocesarFuturo />} />
          <Route exact path="/postulacion" element={<Postulacion />} />
        </Routes>
      </div>
      <ModalLoad 
      style={{ zIndex: "1055" }}/>
    </div>
  );
}
