import { useState } from 'react';
import { NAME_LOCALSTORAGE_TOKEN } from '../config/configBase';


export function useToken() {
  
    const getToken = () => {
      const tokenString = localStorage.getItem(NAME_LOCALSTORAGE_TOKEN);
      if(tokenString==="" || tokenString===null){
        return "";
      }
      const userToken = JSON.parse(tokenString);
      return userToken
      
    };
    
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem(NAME_LOCALSTORAGE_TOKEN, JSON.stringify(userToken));
        setToken(userToken);
      };

      return {
        setToken: saveToken,
        token
      }
  }
