import { Modal } from "react-bootstrap";
import imgExample from "../../assets/img/pago/nro_cuenta.jpg"

const ModalExampleNumTransferencia = (props) => {
  return (
    <Modal
      show={props.show}
      size={"lg"}
      onHide={props.onHide}
      centered={true}
      keyboard={false}
    >
      <Modal.Body className="tx-13"
      style={{background:"darkgray"}}>
        <div>
        <img src={imgExample} className="img-fluid" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalExampleNumTransferencia;
