import { useState } from "react";
import { mostrarAlertaInfo } from "../functions/alertas";
import { httpPost } from "../http/request";
import { Button, Modal, Row } from "react-bootstrap";
import { useUsuario } from "../context/UsuarioContext";
import { useNavigate } from "react-router-dom";

const ModalRegistrarPersona = (props) => {
  const { infoBasicData, loadData, setToken, setSpinnerLoad } = useUsuario();
  const navigate = useNavigate();

  const [ci, setCi] = useState("");
  const [personaSiri, setPersonaSiri] = useState();

  const [formValue, setformValue] = useState({});
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    console.log(formValue);
    setSpinnerLoad(true);
    httpPost(formValue, "/api/v1/usuario/registrarNuevaPersona").then((d) => {
      console.log(d);
      if (d) {
        loadData();
      }
      setSpinnerLoad(false);
    });
  };

  function cl(e) {
    e.preventDefault();
    setToken("");
    navigate("/");
  }

  const verificarCI = (d) => {
    if (!d) {
      mostrarAlertaInfo("Ingrese un número de CI correcto");
      return 0;
    }
    setSpinnerLoad(true);
    httpPost({ ci: d }, "/api/v1/persona/verificaraPorCi").then((dd) => {
      console.log(dd);
      if (dd) {
        setPersonaSiri(dd);
        if (dd.registrar_nuevo) {
          setformValue({
            ci: d,
          });
        }
      }
      setSpinnerLoad(false);
    });
  };

  const continuar = (data) => {
    setSpinnerLoad(true);
    httpPost(data, "/api/v1/usuario/registrarPersona").then((d) => {
      console.log(d);
      if (d) {
        loadData();
      }
      setSpinnerLoad(false);
    });
  };

  return (
    <>
      <Modal
        show={infoBasicData?.registrar ?? true}
        size={"lg"}
        //onHide={props.onHide}
        centered={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h5 className="tx-18 tx-sm-20 mg-b-20">
            Registro de Usuario{" "}
            <button className="btn btn-sm btn-danger" onClick={cl}>
              Salir
            </button>
          </h5>

          <div className="form-group col-sm-12">
            <label className="mb-3">Número de C.I.</label>
            <div className="input-group mb-3">
              <input
                type={"text"}
                className="form-control"
                placeholder={"Ingrese su número de indentificación personal CI"}
                value={ci}
                onChange={(e) => setCi(e.target.value)}
              />
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => verificarCI(ci)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </button>
            </div>
          </div>

          <hr />
          {personaSiri ? (
            <div>
              {personaSiri.registrar_nuevo ? (
                <>
                  <h5 className="mb-3">
                    No se encontraron registros, debe ingresar sus datos
                  </h5>
                  <Row>
                    <div className="form-group col-sm-4">
                      <label className="mb-2">CI</label>
                      <input
                        name="ci"
                        type={"text"}
                        className="form-control"
                        placeholder={""}
                        disabled={true}
                        defaultValue={ci}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-group col-sm-8">
                      <label className="mb-2">Nombre</label>
                      <input
                        name="nombre"
                        type={"text"}
                        className="form-control text-uppercase"
                        placeholder={""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="mb-2">Primer Apellido</label>
                      <input
                        name="1ap"
                        type={"text"}
                        className="form-control text-uppercase"
                        placeholder={""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="mb-2">Segundo Apellido</label>
                      <input
                        name="2ap"
                        type={"text"}
                        className="form-control text-uppercase"
                        placeholder={""}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="mb-2">Correo Electrónico</label>
                      <input
                        name="email"
                        type={"email"}
                        className="form-control"
                        value={infoBasicData?.correo}
                        disabled={true}
                      />
                    </div>
                    <div className="form-group col-sm-6">
                      <label className="mb-2">Celular</label>
                      <input
                        name="cell"
                        type={"number"}
                        className="form-control text-uppercase"
                        placeholder={""}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-12 text-center">
                      <Button variant="success" onClick={handleSubmit}>
                        Registrarme Ahora
                      </Button>
                    </div>
                  </Row>
                </>
              ) : (
                <>
                  <h4>Bienvenido</h4>
                  <h6>
                    {personaSiri.nombre} {personaSiri.paterno}{" "}
                    {personaSiri.materno}
                  </h6>
                  <div className="text-end">
                    <Button
                      variant="success"
                      onClick={() => continuar(personaSiri)}
                    >
                      Continuar Con Estos Datos
                    </Button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}

          {/*<p className="tx-13 tx-color-03 mg-b-30">
            You can add more information than what you see here, such as address
            and birthday by clicking{" "}
            <span className="tx-color-02">Add More Fields</span> button below to
            bring up more options.
    </p>*/}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalRegistrarPersona;
