import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { httpGet, httpPost } from "../../http/request";
import { Link } from "react-router-dom";
import { mostrarAlertaInfo } from "../../functions/alertas";
import ModalRegistrarPersona from "../../componentes/ModalRegistrarUsuario";
import VerificarCorreo from "../../layouts/VerificarCorreo";

const Home = () => {
  var coloresBg = ["bg-teal", "bg-dark", "bg-indigo"];
  const [tramites, setTramites] = useState([]);
  const [registrarPersona, setRegistrarPersona] = useState(true);

  useEffect(() => {
    loadProcesos();
  }, []);

  const loadProcesos = () => {
    httpGet("/api/v1/procesos/listaDisponible").then((d) => {
      console.log(d);
      if (d) {
        setTramites(d);
      }
    });
  };

  const cartTramites = (tram) =>
    tram.map((d, i) => (
      <div className="col-sm-6 col-md-3 p-2" key={i}>
        <div className="card card-profile">
          <img src={d.imagenUrl} className="card-img-top bg-secondary" alt="" />
          <div className="card-body tx-13">
            <div>
              <Link>
                <div className="avatar avatar-lg">
                  <span
                    className={
                      "avatar-initial rounded-circle " +
                      coloresBg[i % coloresBg.length]
                    }
                  >
                    {d.unidad}
                  </span>
                </div>
              </Link>
              <h5>
                <Link>{d.nombre}</Link>
              </h5>
              {/*<p>5355 Realizados</p>*/}

              {/*<div className="img-group img-group-sm mg-b-5">
            <img src="../../assets/img/img11.jpg" className="img wd-40 ht-40 rounded-circle" alt=""/>
            <img src="../../assets/img/img12.jpg" className="img wd-40 ht-40 rounded-circle" alt=""/>
            <img src="../../assets/img/img13.jpg" className="img wd-40 ht-40 rounded-circle" alt=""/>
            <img src="../../assets/img/img14.jpg" className="img wd-40 ht-40 rounded-circle" alt=""/>
            <img src="../../assets/img/img15.jpg" className="img wd-40 ht-40 rounded-circle" alt=""/>
</div>*/}
              <div className="mg-b-25">
                <span className="tx-12 tx-color-03">
                  {d.cantidadActvidades} Actividades
                </span>
              </div>

              {d.habilitado ?? false ? (
                <Link
                  to="tramite/detalle/"
                  state={{ tramite: d }}
                  className="btn btn-outline-primary w-100"
                >
                  Ver Detalles
                </Link>
              ) : (
                <button className="btn btn-outline-secondary w-100" disabled>
                  {" "}
                  No habilitado para su usuario{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <>
      <div className="content content-fixed bd-b">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item active" aria-current="page">
                    Inicio
                  </li>
                </ol>
              </nav>
              <h4 className="mg-b-0">Tramites disponibles para iniciar</h4>
            </div>
            <div className="search-form mg-t-20 mg-sm-t-0">
              <input
                type="search"
                className="form-control"
                placeholder="Buscar un Tramite"
              />
              <button className="btn" type="button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="row">
            <div className="col-lg-12">
              <VerificarCorreo />
              <div className="row row-xs mg-b-25">{cartTramites(tramites)}</div>
            </div>
            {/*<div className="col-lg-3 mg-t-40 mg-lg-t-0">
            <div className="d-flex align-items-center justify-content-between mg-b-20">
              <h6 className="tx-uppercase tx-semibold mg-b-0">Popular Groups</h6>
            </div>
            <ul className="list-unstyled media-list mg-b-15">
              <li className="media align-items-center">
                <a href=""><div className="avatar"><img src="../../assets/img/img26.jpg" className="rounded-circle" alt=""/></div></a>
                <div className="media-body pd-l-15">
                  <h6 className="mg-b-2"><a href="" className="link-01">Human Resources</a></h6>
                  <span className="tx-13 tx-color-03">1,232,099 Members</span>
                </div>
              </li>
              <li className="media align-items-center mg-t-15">
                <a href=""><div className="avatar"><span className="avatar-initial rounded-circle bg-dark">ui</span></div></a>
                <div className="media-body pd-l-15">
                  <h6 className="mg-b-2"><a href="" className="link-01">UI Designers World</a></h6>
                  <span className="tx-13 tx-color-03">1,055,767 Members</span>
                </div>
              </li>
              <li className="media align-items-center mg-t-15">
                <a href=""><div className="avatar"><span className="avatar-initial rounded-circle bg-gray-500">b</span></div></a>
                <div className="media-body pd-l-15">
                  <h6 className="mg-b-2"><a href="" className="link-01">Backend Developers</a></h6>
                  <span className="tx-13 tx-color-03">1,002,005 Members</span>
                </div>
              </li>
              <li className="media align-items-center mg-t-15">
                <a href=""><div className="avatar"><span className="avatar-initial rounded-circle bg-indigo">b</span></div></a>
                <div className="media-body pd-l-15">
                  <h6 className="mg-b-2"><a href="" className="link-01">Bootstrap Wizards</a></h6>
                  <span className="tx-13 tx-color-03">1,032,292  Members</span>
                </div>
              </li>
              <li className="media align-items-center mg-t-15">
                <a href=""><div className="avatar"><span className="avatar-initial rounded-circle bg-pink">s</span></div></a>
                <div className="media-body pd-l-15">
                  <h6 className="mg-b-2"><a href="" className="link-01">SASS Gurus</a></h6>
                  <span className="tx-13 tx-color-03">990,010  Members</span>
                </div>
              </li>
            </ul>

            <h6 className="tx-uppercase tx-semibold mg-t-50 mg-b-15">Groups By Position</h6>

            <nav className="nav nav-classic tx-13">
              <a href="" className="nav-link"><span>Software Engineer</span> <span className="badge">20</span></a>
              <a href="" className="nav-link"><span>UI/UX Designer</span> <span className="badge">18</span></a>
              <a href="" className="nav-link"><span>Sales Representative</span> <span className="badge">14</span></a>
              <a href="" className="nav-link"><span>Product Representative</span> <span className="badge">12</span></a>
              <a href="" className="nav-link"><span>Full-Stack Developer</span> <span className="badge">10</span></a>
            </nav>

            <h6 className="tx-uppercase tx-semibold mg-t-50 mg-b-15">Groups By Location</h6>

            <nav className="nav nav-classic tx-13">
              <a href="" className="nav-link"><span>San Francisco, California</span> <span className="badge">20</span></a>
              <a href="" className="nav-link"><span>Los Angeles, California</span> <span className="badge">18</span></a>
              <a href="" className="nav-link"><span>Las Vegas, Nevada</span> <span className="badge">14</span></a>
              <a href="" className="nav-link"><span>Austin, Texas</span> <span className="badge">12</span></a>
              <a href="" className="nav-link"><span>Arlington, Nebraska</span> <span className="badge">10</span></a>
            </nav>
          </div>*/}
          </div>
        </div>
      </div>

      <ModalRegistrarPersona />
    </>
  );
};

export default Home;
