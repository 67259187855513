import { useEffect, useState } from "react";
import { httpPost } from "../http/request";
import { IoDocumentText } from "react-icons/io5";
import { Modal, Row } from "react-bootstrap";

const ModalVerDetalleTramite = (props) => {
  const [formResp, setFormResp] = useState([]);
  const [dataPersona, setDataPersona] = useState({});

  useEffect(() => {
    if (props.show) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const loadData = () => {
    httpPost(
      { codeIniciado: props.iniData.codeIniciado },
      "/api/v1/actividad/detalleTramite"
    ).then((d) => {
      console.log(d);
      if (d) {
        setFormResp(d.proceso);
        setDataPersona(d.solicitante);
      }
    });
  };

  const formsRespuesta = (form) =>
    form.map((d, i) => (
      <div key={i} className="col-md-6 col-sm-6 col-lg-4">
        <label className="form-label tx-color-03">{d.name}:</label>
        {d.data === "file" ? (
          <div className="text-center">
            <a
              href={d.respuesta}
              target="blank"
              className="btn btn-info btn-sm text-white p-1"
            >
              <IoDocumentText /> Ver Documento Cargado
            </a>
          </div>
        ) : (
          <p className="ms-3">{d.respuesta}</p>
        )}
      </div>
    ));

  const personalesData = (d) => (
    <div>
      <table className="table table-borderless">
        <tbody>
          <tr>
            <th>
              <strong> Nombre :: </strong>
            </th>
            <td className="text-start">
              {d.nombres} {d.primerApellido} {d.segundoApellido}
            </td>
            <th>
              <strong> C.I. :: </strong>
            </th>
            <td className="text-start">{d.ci}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const seccionsFormRespuesta = (secc) =>
    secc.map((d, i) => (
      <li
        className={
          d.procesado
            ? "step-item complete col-12"
            : d.actual
            ? "step-item active"
            : "step-item disabled"
        }
        key={i}
      >
        <span className="step-link pointer">
          <span className="step-number">{i + 1}</span>
          <div>
            <span className="step-title">{d.actividad}</span>
            <span className="step-desc">
              {d.rol}
              {d.usuario ? " - " + d.usuario : ""}
            </span>
            <span className="step-desc">{d.fecha}</span>
          </div>
        </span>
        <ul>
          <li className="col-12">
            <div className="pt-3 pb-3">
              {!d.procesado && d.actual ? (
                <div>
                  <div className="fw-bold">
                    {props.iniData.codeEstado === "A"
                      ? "A la espera para ser procesado..."
                      : props.iniData.estado}
                  </div>
                  {props.iniData.codeEstado === "R" ? (
                    <div className="mt-3 mb-3 d-flex w-100">
                      <div className=" bg-gray-50 pd-y-15 pd-x-15 pd-sm-x-20 m-auto">
                        <p className="text-danger m-0 text-center">
                          {props.iniData.obs}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              <Row className="me-1 ms-1 p-2">
                {formsRespuesta(d.respuestas ?? [])}
              </Row>
            </div>
          </li>
        </ul>
      </li>
    ));

  return (
    <Modal
      show={props.show}
      size={"xl"}
      onHide={props.onHide}
      centered={true}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Body className="tx-13">
        <span
          className="close pos-absolute t-20 r-20 text-secondary pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={props.onHide}>
            {" "}
            ×
          </span>
        </span>
        <h5 className="tx-18 tx-sm-20 mg-b-20">
          Estado de Trámite - {props.iniData.codeIniciado}
        </h5>
        {/*
            <p className="tx-13 tx-color-03 mg-b-20">
              You can add more information than what you see here, such as address
              and birthday by clicking{" "}
              <span className="tx-color-02">Add More Fields</span> button below to
              bring up more options.
            </p>*/}

        <div>
          <div>
            <div className="divider-text mb-3 text-center mt-4">
              Datos Personales del Solicitante
            </div>
            {personalesData(dataPersona)}
          </div>

          <div className="divider-text mb-3 text-center">
            Actividades Realizadas
          </div>
          <div>
            <ul className="steps steps-vertical">
              {seccionsFormRespuesta(formResp)}
            </ul>
          </div>
        </div>
        <br />
      </Modal.Body>
    </Modal>
  );
};

export default ModalVerDetalleTramite;
