import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { httpPost } from "../../http/request";

const ModalHistorial = (props) => {
  const [datoHistorial, setDatoHistorial] = useState([]);

  useEffect(() => {
    if (props.show) {
      const loadData = () => {
        httpPost(
          { codeIniciado: props.ini.codeIniciado },
          "/api/v1/iniciado/historialUsuarioCode"
        ).then((d) => {
          console.log(d);
          if (d) {
            setDatoHistorial(d);
          }
        });
      };
      if (props.show) {
        loadData();
      }
    }
  }, [props.show]);

  const datas = (d) =>
    d.map((d, i) => (
      <tr key={i}>
        <td>{d.fechaInicio}</td>
        <td>{d.tipo}</td>
        <td
          className={
            d.codeEstado === "R"
              ? "text-danger"
              : d.codeEstado === "T"
              ? "text-success"
              : ""
          }
        >
          {d.estado}
        </td>
        <td>{d.proceso}</td>
        <td>
          <>
            <p className="m-0">
              <a
                data-bs-toggle="collapse"
                href={"#collapDetall" + i}
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Ver más detalles
              </a>
            </p>
            <div className="collapse" id={"collapDetall" + i}>
              <div className="">
                {d.respuestas.map((dd, ii) => (
                  <p key={ii} className="m-0" style={{ fontSize: "10px" }}>
                    <strong>{dd.name}:</strong> {dd.respuesta}
                  </p>
                ))}
              </div>
            </div>
          </>
        </td>
      </tr>
    ));

  return (
    <>
      <Modal
        show={props.show}
        size={"xl"}
        onHide={props.onHide}
        centered={true}
        backdrop="static"
        keyboard={false}
        backdropClassName="z-infini"
        className="z-infini"
      >
        <Modal.Body>
          <span
            className="close pos-absolute t-20 r-20 text-secondary pointer"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={props.onHide}>
              {" "}
              ×
            </span>
          </span>
          {
            <p className="tx-13 tx-color-03 mg-b-30">
              Historial de solicitudes similares realizados por el usuario{" "}
              <span className="tx-color-02">{props.ini.solicitante}</span>
            </p>
          }

          <div className="mt-0">
            <table className="table table-sm">
              <thead>
                <tr className="table-dark">
                  <th>FECHA</th>
                  <th>TIPO DE TRAMITE</th>
                  <th>ESTADO</th>
                  <th>NIVEL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody style={{ fontSize: "12px" }}>{datas(datoHistorial)}</tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalHistorial;
