import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import 'bootstrap/dist/css/bootstrap.min.css'
//import './assets/styles/bootstrap.min.css'
import './assets/styles/dashforge/dashforge.css'
//import './assets/styles/dashforge/dashforge.dashboard.css'
import './assets/styles/remixicons/remixicon.css'

import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename={'/'}>
    <GoogleOAuthProvider clientId="131960180805-i8dvvekak4tjsj3iguhkthenr2kr5qk3.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </BrowserRouter>
);