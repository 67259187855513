
import { RiWifiOffLine } from "react-icons/ri";

const FueraDeLinea = () => {

    return (<>
        <div className="content content-fixed content-auth-alt mt-5">
            <div className="container ht-100p tx-center">
                <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                    <div className="wd-70p wd-sm-250 wd-lg-300 mg-b-15">
                        <RiWifiOffLine style={{fontSize:"100px"}} />
                    </div>
                    <h1 className="tx-color-01 tx-24 tx-sm-32 tx-lg-36 mg-xl-b-5">Fuera de Linea</h1>
                    <h5 className="tx-16 tx-sm-18 tx-lg-20 tx-normal mg-b-20">Su dispositivo no tiene acceso a internet</h5>
                    <p className="tx-color-03 mg-b-30">Esto impedirá que el sistema funciones correctamente, es por ello que se muestra este mensaje hasta que retorne la conexión, podrías revisar tu servicio de internet (WIFI, Datos)</p>
                    <span className="tx-12 tx-color-03">Universidad Amazónica de Pando</span>
                </div>
            </div>
        </div>
    </>);

}

export default FueraDeLinea;