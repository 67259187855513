import { useCallback, useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { httpGet, httpPost, httpPostMultiPart } from "../../http/request";
import {
  mostrarAlertaDanger,
  mostrarAlertaSuccess,
} from "../../functions/alertas";
import ReactSelect from "react-select";
import { useUsuario } from "../../context/UsuarioContext";
import VerificarCorreo from "../../layouts/VerificarCorreo";

const DetalleTramite = () => {
  const navigate = useNavigate();

  const { infoBasicData } = useUsuario();

  const location = useLocation();
  const dataTramite = location.state?.tramite ?? {};

  const [viewModalNew, setViewModalNew] = useState(false);

  const [actividades, setActividades] = useState([]);
  const [formularioIni, setFormularioIni] = useState([]);

  const [usuariosRoles, setUsuariosRoles] = useState([]);

  useEffect(() => {
    if (Object.keys(dataTramite).length === 0) {
      navigate("/");
    } else {
      getStepsProceso(dataTramite.code);
      getFormularioProcesoInicial(dataTramite.code);
      getListaDeUsuarios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStepsProceso = (id) => {
    httpPost({ proceso: id }, "/api/v1/actividad/listaPorProcesoSimple").then(
      (d) => {
        console.log(d);
        if (d) {
          setActividades(d);
        }
      }
    );
  };

  const getFormularioProcesoInicial = (id) => {
    httpPost({ proceso: id }, "/api/v1/form/listaDeProcesoInicial").then(
      (d) => {
        console.log(d);
        if (d) {
          setFormularioIni(d);
        }
      }
    );
  };

  const getListaDeUsuarios = () => {
    httpGet("/api/v1/usuario/listaPosibleInmediatoSuperior").then((d) => {
      console.log(d);
      if (d) {
        let l = [];
        d.lista_usuarios.forEach((e) => {
          l.push({ value: e["code"], label: e["nombre"] + " - " + e["rol"] });
        });
        setUsuariosRoles(l);
      }
    });
  };
  const stepsProceso = (activs) =>
    activs.map((d, i) => (
      <li key={i} className="step-item active">
        <span className="step-link">
          <span className="step-number">{i + 1}</span>
          <strong className="step-title">{d.actividad}</strong>
        </span>
        <span className="step-desc">Procesado por {d.rol}</span>
      </li>
    ));

  const inputsProceso = (form) =>
    form.map((d, i) => (
      <div key={i} className="col-md-4 col-sm-12  mb-4">
        <label className="tx-10 tx-medium tx-spacing-1 tx-color-03 tx-uppercase tx-sans mg-b-10">
          {d.name}
        </label>
        <p className="tx-primary tx-rubik mg-b-0">{d.descripcion}</p>
      </div>
    ));

  return (
    <>
      <div className="content content-fixed bd-b">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="d-sm-flex align-items-center justify-content-between">
            <div>
              <h4 className="mg-b-5">{dataTramite.nombre}</h4>
              <p className="mg-b-0 tx-color-03">{dataTramite.descripcion}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
          <div className="row">
            <div className="col-lg-3 col-xl-2 d-none d-lg-block">
              <label className="tx-sans tx-10 tx-medium tx-spacing-1 tx-uppercase tx-color-03 mg-b-15">
                Flujo del proceso del trámite
              </label>

              <ul className="steps steps-vertical steps-sm">
                {stepsProceso(actividades)}
              </ul>

              <br />
            </div>

            <div className="col-lg-9 col-xl-10">
              {/*<Row>
                <Col>
                  <div className="bd pd-20">
                    <label className="tx-sans tx-10 tx-medium tx-spacing-1 tx-uppercase tx-color-03 mg-b-10">
                      Decripción del Proceso
                    </label>
                    <p>asdasd asd asd as</p>
                  </div>
                </Col>
  </Row>*/}

              <VerificarCorreo />

              <h4 className="mg-b-25 mt-4">Requisitos para Iniciar Tramite</h4>
              <Row className="">
                <Col sm={12} className="">
                  <div className="bd contact-content-body ps">
                    <div className="tab-content">
                      <div
                        id="contactInformation"
                        className="tab-pane show active pd-20 pd-xl-25"
                        role="tabpanel"
                      >
                        <div className="text-end">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              if (infoBasicData?.correo ?? false) {
                                setViewModalNew(true);
                              } else {
                                mostrarAlertaDanger(
                                  "No tiene registrado un correo electronico, es necesario registrar uno antes de continuar"
                                );
                              }
                            }}
                          >
                            <span className="mg-l-5">Iniciar Tramite</span>
                          </button>
                        </div>

                        {formularioIni.map((d, i) => (
                          <div key={i}>
                            <div className="d-flex align-items-center justify-content-between mg-b-25">
                              <h6 className={i ? "mg-t-20" : "mg-b-0"}>
                                {d.formulario}
                              </h6>
                              <div className="d-flex"></div>
                            </div>

                            <div className="row ">{inputsProceso(d.forms)}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <br></br>

              {/*
                <div className="row">
                <div className="col-sm-6">
                  <div className="bd pd-20">
                    <label className="tx-sans tx-10 tx-medium tx-spacing-1 tx-uppercase tx-color-03 mg-b-10">
                      NPM Installation
                    </label>
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li>
                        <a href="" className="link-01">
                          Common errors when intalling NPM
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          NPM for Windows
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          Cannot install npm in mac
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          NPM does not recognize in local
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          NPM version not showing when doing command
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 mg-t-20 mg-sm-t-0">
                  <div className="bd pd-20">
                    <label className="tx-sans tx-10 tx-medium tx-spacing-1 tx-uppercase tx-color-03 mg-b-10">
                      Bootstrap Installation
                    </label>
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li>
                        <a href="" className="link-01">
                          Common errors when intalling Bootstrap
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          Migrating from Bootstrap 3
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          Bootstrap using Webpack
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          Bootstrap react components
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          Example of bootsrap page using navbar
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 mg-t-20 mg-sm-t-30">
                  <div className="bd pd-20">
                    <label className="tx-sans tx-10 tx-medium tx-spacing-1 tx-uppercase tx-color-03 mg-b-10">
                      SASS Customization
                    </label>
                    <ul className="list-unstyled lh-7 mg-b-0">
                      <li>
                        <a href="" className="link-01">
                          Minimize sass to css without bootstrap
                        </a>
                      </li>
                      <li>
                        <a href="" className="link-01">
                          Adding more utilities classes in sass
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              */}
            </div>
          </div>

          <hr className="mg-t-60 mg-b-30" />
          {/*
          <div className="row">
            <div className="col-sm-6 col-lg-3">
              <div className="card card-help">
                <div className="card-body tx-13">
                  <div className="tx-60 lh-0 mg-b-25">
                    <ion-icon
                      name="cog-outline"
                      aria-label="cog outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </div>
                  <h5>
                    <a href="" className="link-01">
                      Integrations
                    </a>
                  </h5>
                  <p className="tx-color-03 mg-b-0">
                    Quis autem vel eum iure reprehenderit qui in ea voluptate
                    velit esse quam nihil molesti...
                  </p>
                </div>
                <div className="card-footer tx-13">
                  <span>18 Topics</span>
                  <a href="" className="tx-18 lh-0">
                    <ion-icon
                      name="arrow-forward-outline"
                      aria-label="arrow forward outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-0">
              <div className="card card-help">
                <div className="card-body tx-13">
                  <div className="tx-60 lh-0 mg-b-25">
                    <ion-icon
                      name="laptop-outline"
                      aria-label="laptop outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </div>
                  <h5>
                    <a href="" className="link-01">
                      Supported Devices
                    </a>
                  </h5>
                  <p className="tx-color-03 mg-b-0">
                    Quis autem vel eum iure reprehenderit qui in ea voluptate
                    velit esse quam nihil molesti...
                  </p>
                </div>
                <div className="card-footer tx-13">
                  <span>22 Topics</span>
                  <a href="" className="tx-18 lh-0">
                    <ion-icon
                      name="arrow-forward-outline"
                      aria-label="arrow forward outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
              <div className="card card-help">
                <div className="card-body tx-13">
                  <div className="tx-60 lh-0 mg-b-25">
                    <ion-icon
                      name="cart-outline"
                      aria-label="cart outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </div>
                  <h5>
                    <a href="" className="link-01">
                      Pricing &amp; Billing
                    </a>
                  </h5>
                  <p className="tx-color-03 mg-b-0">
                    Quis autem vel eum iure reprehenderit qui in ea voluptate
                    velit esse quam nihil molesti...
                  </p>
                </div>
                <div className="card-footer tx-13">
                  <span>11 Topics</span>
                  <a href="" className="tx-18 lh-0">
                    <ion-icon
                      name="arrow-forward-outline"
                      aria-label="arrow forward outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-3 mg-t-20 mg-sm-t-30 mg-lg-t-0">
              <div className="card card-help">
                <div className="card-body tx-13">
                  <div className="tx-60 lh-0 mg-b-25">
                    <ion-icon
                      name="file-tray-full-outline"
                      aria-label="file tray full outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </div>
                  <h5>
                    <a href="" className="link-01">
                      Features
                    </a>
                  </h5>
                  <p className="tx-color-03 mg-b-0">
                    Quis autem vel eum iure reprehenderit qui in ea voluptate
                    velit esse quam nihil molesti...
                  </p>
                </div>
                <div className="card-footer tx-13">
                  <span>15 Topics</span>
                  <a href="" className="tx-18 lh-0">
                    <ion-icon
                      name="arrow-forward-outline"
                      aria-label="arrow forward outline"
                      role="img"
                      className="md hydrated"
                    ></ion-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
*/}
        </div>
      </div>

      <ModalNewIniciarTramite
        show={viewModalNew}
        onHide={() => setViewModalNew(false)}
        form={formularioIni}
        proceso={dataTramite}
        users={usuariosRoles}
      />
    </>
  );
};

export default DetalleTramite;

const ModalNewIniciarTramite = (props) => {
  const { setSpinnerLoad } = useUsuario();
  const navigate = useNavigate();

  const [form, setForm] = useState([]);

  const [formValue, setformValue] = useState({});

  const [checkVia, setCheckVia] = useState(false);
  const [codeVia, setCodeVia] = useState("");

  useEffect(() => {
    if (props.show) {
      setformValue({});
      setForm(props.form);
    }
  }, [props]);

  const handleChangeInput = (event) => {
    actualizarCampo(event.target.name, event.target.value);
  };

  const getDataApi = (url, codeForm) => {
    actualizarCampo(codeForm, "...");
    httpGet(url).then((d) => {
      console.log(d);
      if (d) {
        actualizarCampo(codeForm, d.val);
      }
    });
  };

  const actualizarCampo = useCallback((nombreCampo, valorCampo) => {
    setformValue((prevState) => ({
      ...prevState,
      [nombreCampo]: valorCampo,
    }));
  }, []); //

  const handleChangeInputDefault = (n, d) => {
    console.log("-----");
    console.log(formValue[n]);
    if (formValue[n] === undefined) {
      console.log("procede a cargar");
      if (d.substring(0, 1) === "/") {
        console.log("-xx-");
        getDataApi(d, n);
      } else {
        console.log("-pppp-");
        actualizarCampo(n, d);
      }
    }
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    /*setformValue({
      ...formValue,
      [name]: files[0], 
    });*/
    actualizarCampo(name, files[0]);
  };

  const handleSubmit = (event) => {
    let formData = new FormData();

    Object.keys(formValue).forEach((e) => {
      formData.append(e, formValue[e]);
    });

    if (checkVia) {
      if (codeVia) {
        formData.append("via", codeVia);
      } else {
        mostrarAlertaDanger(
          "Tiene selecionada la opción AGREGAR VIA, debe completar la información necesaria"
        );
        return;
      }
    }

    formData.append("proceso", props.proceso?.code);

    console.log(formData);
    setSpinnerLoad(true);
    httpPostMultiPart(formData, "/api/v1/actividad/iniciarProceso").then(
      (d) => {
        if (d) {
          mostrarAlertaSuccess("Tramite Iniciado exitosamente");
          navigate("/iniciados");
        } else {
          mostrarAlertaDanger(
            "Ocurrió un error inesperado, verifique los datos del formulario e intente nuevamente"
          );
        }
        setSpinnerLoad(false);
      }
    );
  };

  const tipoInput = (d) => {
    if (d.default) {
      handleChangeInputDefault("r-" + d.code, d.default);
    }
    switch (d.tipo) {
      case "input":
        if (d.data === "disable") {
          return (
            <input
              type={"text"}
              name={"r-" + d.code}
              className="form-control"
              disabled={true}
              value={formValue["r-" + d.code]}
            />
          );
        }
        return (
          <input
            type={d.data}
            name={"r-" + d.code}
            className="form-control"
            placeholder={d.name}
            //defaultValue={d.default}
            disabled={d.data === "disable"}
            value={formValue["r-" + d.code]}
            onChange={handleChangeInput}
          />
        );

      case "select":
        return (
          <select
            className="form-select"
            required=""
            name={"r-" + d.code}
            onChange={handleChangeInput}
            defaultValue={""}
          >
            <option disabled value={""}>
              seleccione {d.name}
            </option>
            {d.values.map((dd, ii) => (
              <option value={dd} key={ii}>
                {dd}
              </option>
            ))}
          </select>
        );
      case "file":
        return (
          <input
            type={"file"}
            accept={d.data}
            name={"r-" + d.code}
            className="form-control"
            onChange={handleFileChange}
          />
        );

      case "area":
        return (
          <textarea
            class="form-control"
            rows="3"
            name={"r-" + d.code}
            placeholder="..."
            onChange={handleChangeInput}
          ></textarea>
        );
      default:
        return <></>;
    }
  };

  const forms = (form) =>
    form.map((d, i) => (
      <div
        key={i}
        className={`form-group ${d.tipo === "area" ? "col-sm-12" : "col-sm-6"}`}
      >
        <label className="form-label">{d.name}</label>
        {tipoInput(d)}
        <p className="m-0 ms-2 tx-11 tx-gray-500">{d.descripcion}</p>
      </div>
    ));

  const seccionsForm = (secc) =>
    secc.map((d, i) => (
      <Row key={i}>
        <div className="divider-text">{d.formulario}</div>
        {forms(d.forms)}
      </Row>
    ));

  return (
    <Modal
      show={props.show}
      size={"lg"}
      onHide={props.onHide}
      centered={true}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <span
          className="close pos-absolute t-20 r-20 text-secondary pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={props.onHide}>
            {" "}
            ×
          </span>
        </span>
        <h5 className="tx-18 tx-sm-20 mg-b-20">Iniciando formulario</h5>
        {/*<p className="tx-13 tx-color-03 mg-b-30">
          You can add more information than what you see here, such as address
          and birthday by clicking{" "}
          <span className="tx-color-02">Add More Fields</span> button below to
          bring up more options.
  </p>*/}

        <div>
          <div>{seccionsForm(form)}</div>
          <div>
            <div className="divider-text">agregar via</div>
            <Form.Check
              type={"checkbox"}
              label={
                "Enviar a mi inmediato superior para que lo valide antes de continuar con el flujo regular del proceso"
              }
              id={"ad"}
              onChange={() => setCheckVia(!checkVia)}
              //onClick={() => setCheckVia(!checkVia)}
              checked={checkVia}
            />
            <p className="m-0 ms-2 tx-11 tx-gray-500">
              Puede <strong>omitir</strong> esta opción si no aplica o si su
              inmediato superior es parte del proceso regular de este tramite.
            </p>
            <div>
              <Collapse in={checkVia}>
                <div className="mt-4 mb-4">
                  <ReactSelect
                    placeholder={"Seleccione una opción"}
                    options={props.users}
                    name=""
                    //value={codeVia}
                    onChange={(e) => setCodeVia(e.value)}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={(e) => handleSubmit(e)}>
          Confirmar Inicio de Tramite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
