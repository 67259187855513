import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";

const ModalConfirm = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        size={"md"}
        onHide={props.onHide}
        centered={true}
        backdrop="static"
        keyboard={false}
        backdropClassName="z-infini"
        className="z-infini"
      >
        <Modal.Body>
          <span
            className="close pos-absolute t-20 r-20 text-secondary pointer"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={props.onHide}>
              {" "}
              ×
            </span>
          </span>
          {<p className="tx-13 tx-color-03 mg-b-20">
          <span className="tx-color-02">{props.titulo}</span>
  </p>}

          <div className="mt-4 mb-4">
            <p className="tx-13 tx-color-03 m-1">{props.descripcion}</p>
          </div>
          <div className="mt-3 text-center">
              <Button variant="primary" onClick={()=>props.onConfirm()}>{props.buttonText}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalConfirm;
