import axios from "axios";
import { verificarCode } from "../functions/verificResponse";
import { mostrarAlertaInfo } from "../functions/alertas";
import { URL_API } from "../config/configBase";
import { infoCod } from "../functions/info";
import download from "downloadjs";


export async function httpPost(data,url) {

    return axios.post(URL_API+url,data, {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        verificarCode(data.data);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlertaInfo("Ocurrió un error al conectar con el servidor");
    });
  }


  export async function httpPostMultiPart(data,url) {

    return axios.post(URL_API+url,data, {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        verificarCode(data.data);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlertaInfo("Ocurrió un error al conectar con el servidor");
    });
  }

  export async function httpGet(url) {

    return axios.get(URL_API+url, {
      headers: {
        'Authorization': `Bearer ${infoCod()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      if (data.data.ok && data.status === 200) {
        return data.data.data;
      } else {
        verificarCode(data.data);
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      mostrarAlertaInfo("Ocurrió un error al conectar con el servidor");
    });
  }

  export async function httpGetBlob(url,fileName) {

    try {
      const response = await axios.get(URL_API+url, {
        responseType: 'arraybuffer', // Indica que la respuesta es un array de bytes
        headers: {
          'Authorization': `Bearer ${infoCod()}`,
        }
      });

      // Crear un Blob a partir de los datos recibidos
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Crear una URL para el Blob
      const pdfUrl = URL.createObjectURL(blob);

      // Abrir una nueva ventana o pestaña con el PDF
      window.open(pdfUrl, '_blank');
    } catch (error) {
      console.error('Error al obtener el PDF:', error);
    }
    
  }

  