import { toast } from "react-hot-toast";
import { useUsuario } from "../context/UsuarioContext";
import { useEffect } from "react";


export const mostrarAlertaDanger = (mensaje) => {
  toast.error(<b>{mensaje}</b>,
    {
      position: "bottom-center",
      style: {
        color: 'white',
        background: 'red'
      },
    }
  );
}

export const mostrarAlertaSuccess = (mensaje) => {
  toast.success(mensaje,
    {
      position: "bottom-center"
    }
  );
}

export const mostrarAlertaInfo = (mensaje) => {
  toast(mensaje, {
    icon: '⚠️',
  });

}

export const exitLogin = (mensaje) => {
  toast.remove();
  toast.error(<Reload msj={mensaje} />
  );
}


const Reload = (props) => {
  const { token, setToken } = useUsuario();
  useEffect(() => {
    setToken("");
  }, []);
  return <b>{props.msj}</b>
}

export const toastNotificacion = (title, mensaje) => {
  toast.custom((t) => (
    <div
      className={`${t.visible ? 'animate-enter' : 'animate-leave'
        } max-w-md w-full bg-white shadow-lg rounded-3 pointer-events-auto d-flex ring-1 ring-black ring-opacity-5 border`}
        style={{ minWidth: "400px" }}
    >
      <div className="flex-1 w-0 p-3">
        <div className="d-flex items-start">
          <div className="flex-shrink-0 pt-">
            <img
              className="hd-50 wd-50 rounded-circle"
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
              alt=""
            />
          </div>
          <div className="ms-3 flex-1">
            <p className="m-0 text-sm font-medium tx-gray-900">
              {title}
            </p>
            <p className="m-0 mt-1 text-sm tx-gray-500">
              {mensaje}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex bd-l bd-gray-200">
        <button
          className="w-full rounded-none rounded-r-lg p-4 flex items-center justify-center tx-indigo text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          style={{ background: "transparent", border: "transparent" }}>
          Cerrar
        </button>
      </div>
    </div>
  ))
} 