import { useState } from "react";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";

const ModalConfirmTramite = (props) => {

  
  const [textArea, setTextArea] = useState("");

  return (
    <>
      <Modal
        show={props.show}
        size={"md"}
        onHide={props.onHide}
        centered={true}
        backdrop="static"
        keyboard={false}
        backdropClassName="z-infini"
        className="z-infini"
      >
        <Modal.Body>
          <span
            className="close pos-absolute t-20 r-20 text-secondary pointer"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" onClick={props.onHide}>
              {" "}
              ×
            </span>
          </span>
          {<p className="tx-13 tx-color-03 mg-b-30">
          Para proceder, debe indicar, si es necesario, las razones de{" "}
          <span className="tx-color-02">{props.tituloAccion}</span>
  </p>}

          <div className="mt-0">
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Aclaración de tramite Rechazado"
            >
              <Form.Control
                as="textarea"
                placeholder=""
                style={{ height: "100px" }}
                onChange={(e)=>setTextArea(e.target.value)}
              />
            </FloatingLabel>
          </div>
          <div className="mt-3 text-center">
              <Button variant="danger" onClick={()=>props.onConfirm(textArea)}>{props.buttonText}</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalConfirmTramite;
