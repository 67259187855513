import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { httpGet, httpPost, httpPostMultiPart } from "../../http/request";
import { TfiReload } from "react-icons/tfi";
import { IoDocumentText } from "react-icons/io5";
import ModalConfirmTramite from "../../componentes/ModalConfirmTramite";
import ModalConfirm from "../../componentes/ModalConfirm";
import { mostrarAlertaInfo } from "../../functions/alertas";
import ModalHistorial from "./ModalHistorial";
import { FcCalendar } from "react-icons/fc";
import { useUsuario } from "../../context/UsuarioContext";

const Recibidos = () => {
  const { setSpinnerLoad } = useUsuario();
  const [tramitsRecib, setTramitsRecib] = useState([]);

  const [viewModalTramiteDetalle, setViewModalTramiteDetalle] = useState(false);
  const [selectedTramite, setSelectedTramite] = useState({});

  useEffect(() => {
    loadIniciados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadIniciados = () => {
    setSpinnerLoad(true);
    httpGet("/api/v1/actividad/listaActividadesPorProcesar").then((d) => {
      console.log(d);
      if (d) {
        setTramitsRecib(d);
      }
      setSpinnerLoad(false);
    });
  };

  const verDetallerTramite = (d) => {
    setViewModalTramiteDetalle(true);
    setSelectedTramite(d);
  };

  const trsTramits = (dTramits) =>
    dTramits.map((d, i) => (
      <tr key={i}>
        <td className="tx-color-03 tx-normal">{d.codeIniciado}</td>
        <td className="tx-color-03 tx-normal">{d.fechaInicio}</td>
        <td className="tx-medium text-start">{d.proceso}</td>
        <td
          className={`tx-medium text-start ${
            d.isTerminado ? "text-primary" : ""
          }`}
        >
          {d.actividad}
        </td>
        <td className="text-center tx-teal">{d.solicitante}</td>
        <td className="text-center tx-pink">{d.posicion}</td>
        <td className="tx-medium text-center">
          <span
            className="text-center text-primary pointer"
            onClick={() => verDetallerTramite(d)}
          >
            Ver
          </span>
        </td>
      </tr>
    ));

  return (
    <>
      <div className="content content-fixed">
        <Container>
          <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
            <div>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                  <li className="breadcrumb-item active" aria-current="page">
                    Recibidos
                  </li>
                </ol>
              </nav>
              <h4 className="mg-b-0 tx-spacing--1">
                Lista de Tramites Recibidos a Procesar
              </h4>
            </div>
            <div className="d-none d-md-block">
              <button className="btn btn-sm pd-x-15 btn-white btn-uppercase mg-l-5">
                <FcCalendar /> Reporte
              </button>

              <button
                className="btn btn-sm pd-x-15 btn-white mg-l-5"
                onClick={() => loadIniciados()}
              >
                <TfiReload />
              </button>

              {/*<button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file wd-10 mg-r-5"
                >
                  <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
                  <polyline points="13 2 13 9 20 9"></polyline>
                </svg>{" "}
                Generate Report
  </button>*/}
            </div>
          </div>

          <Row>
            <Col lg={12} xl={12} className="mg-t-10">
              <div className="card mg-b-10">
                {/*<div className="card-header pd-t-20 d-sm-flex align-items-start justify-content-between bd-b-0 pd-b-0">
                  <div>
                    <h6 className="mg-b-5">Your Most Recent Earnings</h6>
                    <p className="tx-13 tx-color-03 mg-b-0">
                      Your sales and referral earnings over the last 30 days
                    </p>
                  </div>
                </div>
                <div className="card-body pd-y-30">
                  <div className="d-sm-flex">
                    <div className="media">
                      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bar-chart-2"
                        >
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </div>
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">
                          Terminados
                        </h6>
                        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">
                          $1,958,104
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-pink tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bar-chart-2"
                        >
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </div>
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          Rechazados
                        </h6>
                        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">
                          $234,769<small>.50</small>
                        </h4>
                      </div>
                    </div>
                    <div className="media mg-t-20 mg-sm-t-0 mg-sm-l-15 mg-md-l-40">
                      <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-primary tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-bar-chart-2"
                        >
                          <line x1="18" y1="20" x2="18" y2="10"></line>
                          <line x1="12" y1="20" x2="12" y2="4"></line>
                          <line x1="6" y1="20" x2="6" y2="14"></line>
                        </svg>
                      </div>
                      <div className="media-body">
                        <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold mg-b-5 mg-md-b-8">
                          En Proceso
                        </h6>
                        <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">
                          $1,608,469<small>.50</small>
                        </h4>
                      </div>
                    </div>
                  </div>
  </div>*/}

                <div className="table-responsive">
                  <table className="table table-dashboard mg-b-0">
                    <thead>
                      <tr>
                        <th className="fw-bold">Código</th>
                        <th className="fw-bold">Fecha Inicio</th>
                        <th className="text-center fw-bold">Tipo</th>
                        <th className="text-center fw-bold">Acción</th>
                        <th className="text-center fw-bold">Solicitante</th>
                        <th className="text-center fw-bold">Proceso</th>
                        <th className="text-center fw-bold">Ver Detalles</th>
                      </tr>
                    </thead>
                    <tbody>{trsTramits(tramitsRecib)}</tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalContinuarTramite
        show={viewModalTramiteDetalle}
        onHide={() => setViewModalTramiteDetalle(false)}
        iniData={selectedTramite}
        onSuccess={() => loadIniciados()}
      />
    </>
  );
};

export default Recibidos;

const ModalContinuarTramite = (props) => {
  const [dataPersona, setDataPersona] = useState({});
  const [stepsProceso, setStepProceso] = useState([]);

  useEffect(() => {
    if (props.show) {
      const loadData = () => {
        httpPost(
          { codeIniciado: props.iniData.codeIniciado },
          "/api/v1/actividad/procesarActividadProceso"
        ).then((d) => {
          console.log(d);
          if (d) {
            setDataPersona(d.solicitante);
            setStepProceso(d.procesoSec);
          }
        });
      };
      if (props.show) {
        loadData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const tramiteAvanzado = () => {
    props.onHide();
    props.onSuccess();
  };

  const formsRespuesta = (form) =>
    form.map((d, i) => (
      <div key={i} className="col-md-6 col-sm-6 col-lg-4">
        <label className="form-label tx-color-03">{d.name}:</label>
        {d.data === "file" ? (
          <div className="text-center">
            <a
              href={d.respuesta}
              target="blank"
              className="btn btn-info btn-sm text-white p-1"
            >
              <IoDocumentText /> Ver Documento Cargado
            </a>
          </div>
        ) : (
          <p className="ms-3">{d.respuesta}</p>
        )}
      </div>
    ));

  const personalesData = (d) => (
    <div>
      <table className="table table-borderless">
        <tbody>
          <tr>
            <th>
              <strong> Nombre :: </strong>
            </th>
            <td className="text-start">
              {d.nombres} {d.primerApellido} {d.segundoApellido}
            </td>
            <th>
              <strong> C.I. :: </strong>
            </th>
            <td className="text-start">{d.ci}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const seccionsFormRespuesta = (secc) =>
    secc.map((d, i) =>
      d.procesado ? (
        <div key={i} className="mt-3">
          <p className="m-0 d-flex">
            <strong className="mg-b-0 text-success">{d.actividad}</strong>
            <span className="tx-9 tx-color-03 ms-auto "> {d.fecha}</span>
          </p>
          <span className="tx-11 tx-color-03 ms-3 ">
            {d.rol}: {d.usuario}
          </span>
          <Row
            className="m-1 border-start p-2"
            style={{ background: "#f8f9fc" }}
          >
            {formsRespuesta(d.respuestas ?? [])}
          </Row>
        </div>
      ) : (
        <span key={i}></span>
      )
    );

  const stepData = (data) => {
    data.forEach((d) => {
      if (d.actual) {
        props.iniData.defUsuario = d.defUsuario;
      }
    });
    return (
      <ul className="steps steps-justified ">
        {data.map((d, i) => (
          <li
            className={
              d.procesado
                ? "step-item complete"
                : d.actual
                ? "step-item active"
                : "step-item disabled"
            }
            key={i}
          >
            <span className="step-link pointer">
              <span className="step-number">{d.orden + 1}</span>
              <div>
                <span className="step-title">{d.actividad}</span>
                <span className="step-desc">{d.rol}</span>
              </div>
            </span>
          </li>
        ))}
      </ul>
    );
  };
  return (
    <Modal
      show={props.show}
      size={"xl"}
      onHide={props.onHide}
      centered={true}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Body className="tx-13">
        <span
          className="close pos-absolute t-20 r-20 text-secondary pointer"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={props.onHide}>
            {" "}
            ×
          </span>
        </span>
        <h5 className="tx-18 tx-sm-20 mg-b-20">{props.iniData?.proceso}</h5>
        {/*
          <p className="tx-13 tx-color-03 mg-b-20">
            You can add more information than what you see here, such as address
            and birthday by clicking{" "}
            <span className="tx-color-02">Add More Fields</span> button below to
            bring up more options.
          </p>*/}

        <div>
          <div>
            <hr />
            {stepData(stepsProceso)}
            <div className="divider-text mb-3 text-center mt-4">
              Datos Personales del Solicitante
            </div>
            {personalesData(dataPersona)}
          </div>

          <div className="divider-text mb-3 text-center">
            Actividades Realizadas
          </div>
          <div>{seccionsFormRespuesta(stepsProceso)}</div>
          <div className="divider-text mb-3 text-center"></div>
          <SeccionContinuarTramite
            iniData={props.iniData}
            onSuccess={tramiteAvanzado}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

const SeccionContinuarTramite = (props) => {
  const { setSpinnerLoad } = useUsuario();
  const [modalHistorial, setModalHistorial] = useState(false);
  const [modalConfirmRecha, setModalConfirmRecha] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);

  const [form, setForm] = useState([]);
  const [formValue, setformValue] = useState({});
  const [usuarioSiguiente, setUsuarioSiguiente] = useState(0);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    httpPost(
      { codeIniciado: props.iniData.codeIniciado },
      "/api/v1/form/formularioActividad"
    ).then((d) => {
      console.log(d);
      if (d) {
        setForm(d);
      }
    });
  };

  const handleChangeInput = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setformValue({
      ...formValue,
      [name]: files[0], // Solo toma el primer archivo, puedes ajustarlo según tus necesidades
    });
  };

  const handleSubmit = (event) => {
    let formData = new FormData();

    Object.keys(formValue).forEach((e) => {
      console.log(e);
      formData.append(e, formValue[e]);
    });

    if (props.iniData.defUsuario) {
      if (!usuarioSiguiente) {
        mostrarAlertaInfo(
          "Debe seleccionar un usuario que continuará el proceso"
        );
        return;
      }
      formData.append("userDef", usuarioSiguiente);
    }

    formData.append("codeIniciado", props.iniData?.codeIniciado);

    console.log(formData);

    setSpinnerLoad(true);
    httpPostMultiPart(formData, "/api/v1/actividad/continuarProceso").then(
      (d) => {
        console.log(d);
        if (d) {
          props.onSuccess();
        }
        setSpinnerLoad(false);
      }
    );
  };

  const rechazarTramite = (d) => {
    setSpinnerLoad(true);
    httpPost(
      { codeIniciado: props.iniData?.codeIniciado, obs: d },
      "/api/v1/actividad/rechazarTramite"
    ).then((d) => {
      console.log(d);
      if (d) {
        props.onSuccess();
        setModalConfirmRecha(false);
      }
      setSpinnerLoad(false);
    });
  };

  const tipoInput = (d) => {
    switch (d.tipo) {
      case "input":
        return (
          <input
            type={d.data}
            name={"r-" + d.code}
            className="form-control"
            placeholder={d.name}
            onChange={handleChangeInput}
          />
        );

      case "select":
        return (
          <select
            className="form-select"
            required=""
            name={"r-" + d.code}
            onChange={handleChangeInput}
          >
            <option value="" disabled="" selected="">
              seleccione {d.name}
            </option>
            {d.values.map((dd, ii) => (
              <option value={dd} key={ii}>
                {dd}
              </option>
            ))}
          </select>
        );
      case "file":
        return (
          <input
            type={"file"}
            accept={d.data}
            name={"r-" + d.code}
            className="form-control"
            onChange={handleFileChange}
          />
        );
      default:
        return <></>;
    }
  };

  const forms = (form) =>
    form.map((d, i) => (
      <div key={i} className="form-group col-sm-6">
        <label className="form-label">{d.name}</label>
        {tipoInput(d)}
        <p className="m-0 ms-2 tx-11 tx-gray-500">{d.descripcion}</p>
      </div>
    ));

  const seccionsForm = (secc) =>
    secc.map((d, i) => (
      <Row key={i}>
        <div className="divider-text">{d.formulario}</div>
        {forms(d.forms)}
      </Row>
    ));

  return (
    <>
      <div>
        {props.iniData?.isTerminado ? (
          <></>
        ) : (
          <div>
            <h5 className="text-start text-primary">
              {props.iniData.actividad}
            </h5>
            <p className="tx-11 text-danger tx-color-03 mg-b-20">
              Nota: Señor Usuario, debe revisar la información ingresada en las
              actividades anteriores, y realizar una acción en los botones mas
              abajo para Continuar, Devolver o Rechazar el tramite
            </p>
            {form.length !== 0 ? (
              seccionsForm(form)
            ) : (
              <div>
                <div className="mt-3 mb-3 d-flex">
                  <div
                    className=" bg-gray-50 pd-y-15 pd-x-15 pd-sm-x-20 m-auto"
                    style={{ width: "250px" }}
                  >
                    <p className="tx-color-03 m-0 text-center">
                      No se requiere ningún dato adicional
                    </p>
                  </div>
                </div>
              </div>
            )}
            {props.iniData.defUsuario ? (
              <div className="alert alert-info">
                <p className="fw-bold">
                  Debe seleccionar un usuario con quien corresponda continuar
                  este proceso
                </p>
                <InputSelectCargo
                  onSuccess={(d) => {
                    setUsuarioSiguiente(d);
                  }}
                />
              </div>
            ) : (
              <></>
            )}

            <Row className="text-center mt-5 mb-3">
              <Col sm={4}>
                <Button
                  variant={"outline-info"}
                  className="m-1"
                  onClick={() => setModalHistorial(true)}
                >
                  Ver Historial de solicitudes similares
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  variant={"outline-light"}
                  className="m-1"
                  onClick={() => setModalConfirmRecha(true)}
                >
                  Rechazar Trámite
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  variant={"outline-success"}
                  className="m-1"
                  onClick={() => setModalConfirm(true)}
                >
                  Confirmar y Avanzar
                </Button>
              </Col>
            </Row>
            <ModalConfirmTramite
              show={modalConfirmRecha}
              onHide={() => setModalConfirmRecha(false)}
              buttonText={"Rechazar Tramite"}
              tituloAccion={"Rechazar tramite"}
              onConfirm={(d) => {
                if (d) {
                  rechazarTramite(d);
                } else {
                  mostrarAlertaInfo(
                    "Debe ingresar necesariamente una descripción de la acción en el cuadro de texto"
                  );
                }
              }}
            />
            <ModalConfirm
              show={modalConfirm}
              onHide={() => setModalConfirm(false)}
              buttonText={"Sí, Confirmar y Avanzar"}
              titulo={"¿Está seguro confirmar y dar continuidad al tramite?"}
              descripcion={
                "Después de esto, no podras realizar alguna acción en este tramite."
              }
              onConfirm={() => {
                handleSubmit();
                setModalConfirm(false);
              }}
            />
            <ModalHistorial
              show={modalHistorial}
              onHide={() => setModalHistorial(false)}
              ini={props.iniData}
            />
          </div>
        )}
      </div>
    </>
  );
};

const InputSelectCargo = (props) => {
  const [lista, setLista] = useState([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    httpGet("/api/v1/general/cargos").then((d) => {
      console.log(d);
      if (d) {
        setLista(d.lista ?? []);
      }
    });
  };

  return (
    <select
      className="form-select"
      onChange={(e) => props.onSuccess(e.target.value)}
    >
      <option selected value={0}>
        Seleccione una Opción
      </option>
      {lista.map((d, i) => (
        <option key={i} value={d.code}>
          {d.nombre}
        </option>
      ))}
    </select>
  );
};
