import React, { useEffect, useState } from "react";
import { useToken } from "../hooks/useToken";
import { httpGet, httpPost } from "../http/request";

const UsuarioContext = React.createContext();

export function UsuarioProvider(props){
    
    const [ tkApp, setTkApp ] = useState("");
    const { token, setToken } = useToken();
    const [infoBasicData, setInfoBasicData] = useState();
    const [spinnerLoad, setSpinnerLoad]=useState(false);

    const loadData=()=>{
       setSpinnerLoad(true);
       httpPost({tkApp},"/api/v1/usuario/datos_basicos").then((dd) => {
        console.log(dd);
        if (dd) {
            setInfoBasicData(dd);
        }
        setSpinnerLoad(false);
      });   

    }
    useEffect(()=>{
        if(token){
            loadData();
        }
        
      },[token]);

      const value = React.useMemo(()=>{
        return({
            token,
            infoBasicData,
            spinnerLoad,
            tkApp,
            setToken,
            setSpinnerLoad,
            setTkApp,
            loadData,
        });
      },[token,infoBasicData,spinnerLoad,tkApp,setToken,setSpinnerLoad,setTkApp,loadData]);

      return <UsuarioContext.Provider value={value} {...props} />
}

export function useUsuario(){
    const context = React.useContext(UsuarioContext);
    if(!context){
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext');
    }
    return context;
}