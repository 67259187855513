import { useGoogleLogin } from "@react-oauth/google";
import { Button, Col, Collapse, Container, Form, Row } from "react-bootstrap";
import { httpPost } from "../../http/request";
import { mostrarAlertaDanger } from "../../functions/alertas";
import { useUsuario } from "../../context/UsuarioContext";
import { useState } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import banner from "./../../assets/img/imgBanner.png";
import logo from "./../../assets/img/LOGOS_UAP_AZUL.png";
import { VERSION_APP } from "../../config/configBase";

const Login = () => {
  const { setToken, tkApp, setSpinnerLoad } = useUsuario();
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [viewPass, setViewPass] = useState(false);

  const [open, setOpen] = useState(false);

  const loginGoogle = (r) => {
    r["tkApp"] = tkApp;
    console.log(r);
    httpPost(r, "/api/v1/login/signGoogle").then((d) => {
      console.log(d);
      if (d) {
        setToken(d.token);
      }
    });
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => loginGoogle(codeResponse),
    error_callback: () => {
      mostrarAlertaDanger("Ocurrio un error al inciar con Google");
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    loginUsuario({ user, pass });
  };

  const loginUsuario = (r) => {
    r["tkApp"] = tkApp;
    console.log(r);
    setSpinnerLoad(true);
    httpPost(r, "/api/v1/login/user").then((d) => {
      console.log(d);
      if (d) {
        setToken(d.token);
      }
      setSpinnerLoad(false);
    });
  };

  return (
    <>
      <div>
        <Container
          style={{ height: "100vh" }}
          className="d-flex align-items-center "
        >
          <Row className=" align-items-center m-0" style={{ width: "100%" }}>
            <Col
              sm={12}
              md={6}
              lg={8}
              className="align-self-center p-2 text-center"
            >
              <div className="d-none d-lg-flex">
                <div className="">
                  <img
                    src={logo}
                    className="img-fluid m-3 mb-3"
                    alt=""
                    style={{ maxWidth: "500px" }}
                  />

                  <div
                    className="b-0 l-0 tx-12 text-muted"
                    style={{ fontSize: "14px" }}
                  >
                    Sistema Informático de Gestion de Tramites Académicos <br />
                    <Link to={"https://uap.edu.bo"} target="_blank">
                      Universidad Amazónica de Pando (uap.edu.bo)
                    </Link>{" "}
                    <br /> v.{VERSION_APP}
                  </div>
                </div>
              </div>
            </Col>
            <Col
              sm={12}
              md={6}
              lg={4}
              className="d-flex justify-content-center align-self-center"
            >
              <div className="mt-5 mb-5" style={{ maxWidth: "350px" }}>
                <div style={{ background: "#f8ffff" }}>
                  <div className="d-grid gap-2 mb-3">
                    <button
                      className="btn btn-primary btn-block btn-brand-02"
                      onClick={() => setOpen(!open)}
                    >
                      Iniciar sesión con mi cuenta de{" "}
                      <strong>Siringuero</strong> o <strong>Perlanet</strong>,
                      <strong>Perla del Acre</strong>
                    </button>
                  </div>
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <Form onSubmit={handleSubmit} className=" mt-4 p-3">
                        <h3 className="tx-color-01 mg-b-5">Inicio de sesión</h3>
                        <p className="text-muted mb-3">
                          ¡Bienvenido! Por favor ingrese su usuario y contraseña
                          para continuar.
                        </p>

                        <div className="form-group mb-4">
                          <label>Usuario</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Ingresa tu usuario"
                            onChange={(e) => setUser(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-4">
                          <div className="d-flex justify-content-between mg-b-5">
                            <label className="mg-b-0-f">Contraseña</label>
                          </div>
                          <div className="" style={{ position: "relative" }}>
                            <input
                              type={viewPass ? "text" : "password"}
                              className="form-control"
                              placeholder="Ingresa tu contraseña"
                              onChange={(e) => setPass(e.target.value)}
                            />
                            <div
                              className="d-flex"
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                              }}
                            >
                              <span
                                className="tx-15 me-1 text-primary pointer m-auto p-1 pe-2"
                                onClick={() => {
                                  setViewPass(!viewPass);
                                }}
                              >
                                {viewPass ? <FaEye /> : <FaEyeSlash />}
                              </span>
                            </div>
                          </div>
                        </div>

                        <button
                          className="btn btn-primary btn-brand-02 w-100 mb-3"
                          type="submit"
                          /*onClick={() => loginUsuario({ user, pass })}*/
                        >
                          Iniciar sesión
                        </button>
                      </Form>
                    </div>
                  </Collapse>
                </div>
                <div className="divider-text mb-3 text-center">o</div>

                <div className="d-grid gap-2 mb-0">
                  <button
                    className="btn btn-danger btn-block p-3"
                    onClick={() => login()}
                  >
                    Iniciar Sesión con Google
                  </button>
                  {/*<GoogleLogin
                    onSuccess={(credentialResponse) => {
                      //console.log(credentialResponse);
                      loginGoogle(credentialResponse);
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  />*/}
                </div>
                <p className="m-0 text-muted" style={{ fontSize: "10px" }}>
                  Elige esta opción si no tienen tienes acceso a algún sistema
                  académico
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Login;
